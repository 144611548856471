import React, { useCallback, useState } from 'react';
import {
  ButtonBase,
  Chip,
  Dialog,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import ChipItem from './Item';

type Props = {
  maxNumber: number;
  data: { id: number; text: string }[];
  icon: React.ReactElement;
  dialogTitle?: string;
  size?: 'small' | 'medium';
  allowRemove?: boolean;
  onTagRemove?: (tagId: number) => void;
  disabled?: boolean;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
};

const StackedChips = (props: Props) => {
  const { data, maxNumber, dialogTitle = '', size = 'small', ...rest } = props;

  const [showAllChips, setshowAllChips] = useState<boolean>(false);

  const openShowAllChipsDialog = useCallback(() => setshowAllChips(true), []);
  const closeShowAllChipsDialog = useCallback(() => setshowAllChips(false), []);

  return (
    <>
      <Stack direction={'row'} flexWrap={'wrap'} spacing={0.5}>
        {data.slice(0, maxNumber || data.length).map((item) => (
          <ChipItem
            key={item.id}
            item={item}
            {...rest}
            size={size}
            disabled={props?.disabled || false}
            color={props.color}
          />
        ))}
        {!!(data.length > maxNumber) && (
          <ButtonBase onClick={openShowAllChipsDialog}>
            <Chip
              label={`+${data.length - maxNumber}`}
              key={'total-count'}
              color={props.color || 'primary'}
              sx={(theme) => ({
                my: `${theme.spacing(0.5)}!important`,
                '&:hover': {
                  cursor: 'pointer'
                }
              })}
              size={size}
            />
          </ButtonBase>
        )}
      </Stack>
      <Dialog open={showAllChips} onClose={closeShowAllChipsDialog}>
        <Paper
          sx={(theme) => ({
            padding: theme.spacing(3)
          })}
        >
          <Typography variant="h3">{dialogTitle}</Typography>
          {data.map((item) => (
            <Chip
              key={item.id}
              label={item.text}
              color={props.color}
              sx={(theme) => ({ margin: theme.spacing(0.5) })}
              icon={rest.icon}
            />
          ))}
        </Paper>
      </Dialog>
    </>
  );
};

export default StackedChips;
