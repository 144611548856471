import {
  useTheme,
  Autocomplete,
  TextField,
  Typography,
  alpha,
  Paper
} from '@mui/material';
import { StyledPopper } from './styles';

interface CustomAutoCompleteProps {
  onChange: (event: any, value: any) => void;
  label?: string;
  placeholder?: string;
  dataSource: any[];
  optionLabel?: string;
  value: any;
  freeSolo?: boolean;
  hideClearIcon?: boolean;
  multiple?: boolean;
  customLabel?: boolean;
  disableSearch?: boolean;
  limitTags?: number;
  popperWidth?: string | number;
  [z: string]: any;
}

const CustomAutoComplete = ({
  onChange,
  label = '',
  customLabel = false,
  optionLabel = '',
  placeholder = '',
  dataSource,
  value,
  freeSolo = false,
  hideClearIcon = false,
  multiple = true,
  disableSearch = false,
  limitTags = 1,
  popperWidth = null,
  ...rest
}: CustomAutoCompleteProps) => {
  const theme = useTheme();
  const { sx, ...restProps } = rest;
  return (
    <>
      {customLabel && (
        <Typography
          sx={{
            marginBottom: '5px',
            marginLeft: '2px',
            color: '#575757',
            fontSize: '13px'
          }}
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        multiple={multiple}
        id={label}
        size="small"
        options={dataSource}
        disableClearable={hideClearIcon}
        freeSolo={freeSolo}
        getOptionLabel={(option: any) =>
          typeof option === 'string'
            ? option
            : optionLabel
            ? option[optionLabel]
            : option?.name
        }
        value={value}
        limitTags={limitTags}
        onChange={onChange}
        PopperComponent={StyledPopper}
        sx={{
          fontSize: theme.typography.pxToRem(12),
          position: 'relative',
          borderRadius: theme.general.borderRadius,
          ...sx
        }}
        autoHighlight={!disableSearch}
        ChipProps={{
          sx: {
            '& .MuiChip-deleteIcon': {
              color: theme.colors.alpha.black[100],
              backgroundColor: 'transparent',
              '&:hover': {
                color: theme.colors.alpha.black[100]
              }
            }
          }
        }}
        componentsProps={{
          clearIndicator: {
            sx: {
              mr: 0.01,
              color: theme.colors.alpha.black[100],
              backgroundColor: 'transparent',
              '&:hover': {
                color: theme.colors.alpha.black[100],
                backgroundColor: theme.colors.alpha.black[10]
              }
            }
          }
        }}
        PaperComponent={(props) => (
          <Paper {...props} style={{ ...props.style, width: popperWidth }} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={customLabel ? null : null}
            maxRows={1}
            disabled={disableSearch}
            sx={(theme) =>
              disableSearch && {
                '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                  {
                    color: 'green',
                    borderColor: alpha(theme.colors.alpha.black[100], 0.23)
                  },
                input: {
                  '&.Mui-disabled': {
                    textFillColor: 'rgba(0, 0, 0, 0.38)'
                  }
                }
              }
            }
            size="small"
            placeholder={placeholder}
            InputLabelProps={{ shrink: customLabel ? false : true }}
          />
        )}
        {...restProps}
      />
    </>
  );
};

export default CustomAutoComplete;
