import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const itJson = {
  // General
  [T.empowerGPT]: 'EmpowerGPT',
  [T.knowledgeBaseChat]: 'Chat della base di conoscenza',
  [T.enterprise]: 'Impresa',
  [T.worldKnowledge]: 'Conoscenza del mondo',
  [T.generalChat]: 'Chat generale',
  [T.languageSwitcher]: 'Cambia Lingua',
  [T.logout]: 'Disconnettersi',
  [T.profile]: 'Profilo',
  [T.uploadImport]: 'Carica / Importa',
  [T.documents]: 'Documenti',
  [T.conversations]: 'Conversazioni',
  [T.valuableInsights]: 'Estrai rapidamente informazioni preziose',
  [T.uploadYourDocuments]: 'Carica documenti',
  [T.clearFilters]: 'Reimposta',
  [T.resetPassword]: 'Cambia Password',
  [T.userGroups]: 'Gruppi Utente',
  [T.userGroupName]: 'Nome del Gruppo Utente',
  [T.accountType]: 'Tipo di Account',
  [T.email]: 'Email',
  [T.startChat]: 'Inizia Chat',
  [T.createdWithTime]: 'Creato {{time}} fa',
  [T.modifiedWithTime]: 'Modificato {{time}} fa',
  [T.syncedWithTime]: 'Sincronizzato {{time}} fa',
  [T.rolesAndPermissions]: 'Ruoli e Autorizzazioni',
  [T.selectItemsToDelete]: 'Seleziona gli elementi da eliminare',
  [T.selectItemsToTag]: 'Seleziona gli elementi da contrassegnare',
  [T.selectItemsToMove]: 'Seleziona gli elementi da spostare',
  [T.noPermissionToDelete]: 'Non hai il permesso di eliminare',
  [T.noPermissionToView]: 'Non hai il permesso di visualizzare',
  [T.noPermissionToMoveHere]: 'Non hai il permesso di spostare qui',
  [T.noPermissionToDownload]: 'Non hai il permesso di scaricare',
  [T.tagDisabledForWorkspaceChat]:
    'La funzionalità di tag non è disponibile per le chat dello spazio di lavoro',
  [T.typeToSearch]: 'Digita per cercare',
  [T.disclaimerEgpt]:
    'EmpowerGPT potrebbe commettere errori, si prega di verificare con le citazioni fornite.',
  [T.usage]: 'Utilizzo',
  [T.reprocess]: 'Riprocessare',
  [T.selectItemsToReprocess]: 'Seleziona gli elementi da riprocessare',
  [T.chatGPT]: 'ChatGPT',
  [T.fileProcessing]: 'Elaborazione di File',
  [T.ok]: 'OK',
  [T.goBack]: 'Indietro',
  [T.settings]: 'Impostazioni',
  [T.llmsSettingsHeading]:
    'Modelli di intelligenza artificiale disponibili in chat',
  [T.selectModels]: 'Seleziona modelli',
  [T.version]: 'Versione',
  [T.configure]: 'Configurare',
  [T.tenantKnowledgeBase]: 'Conoscenza {{tenant}}',

  //Filters
  [T.search]: 'Ricerca',
  [T.accountTypeSelect]: 'Seleziona il Tipo di Account',
  [T.selected]: `Selezionato`,

  // Documents
  [T.uploadDocs]: 'Carica Documenti',
  [T.tagDocuments]: 'Etichetta Documenti',
  [T.allDocuments]: 'Tutti i documenti',
  [T.docName]: 'Nome del Documento',
  [T.uploadedBy]: 'Caricato da',
  [T.uploadedOn]: 'Caricato il',
  [T.actions]: 'Azioni',
  [T.view]: 'Visualizza',
  [T.sync]: 'Sincronizza',
  [T.share]: 'Condividi',
  [T.sharedWith]: 'Condiviso con',
  [T.invite]: 'Invita',
  [T.details]: 'Dettagli',
  [T.documentType]: 'Tipo di Documento',
  [T.size]: 'Dimensione',
  [T.length]: 'Lunghezza',
  [T.description]: 'Descrizione',
  [T.rowsPerPage]: 'Righe per pagina',
  [T.uploadTimeAndDateWithUser]: 'Caricato il {{time}} da {{user}}',
  [T.download]: 'Scarica',
  [T.uploadProgress]: 'Caricare i progressi',
  [T.document]: 'documento',
  [T.docsDropMessage]: 'Rilascia i documenti per iniziare il caricamento.',
  [T.uploadDocFailed]: 'Impossibile caricare i documenti.',
  [T.uploadDocsFailed]: 'Impossibile caricare il documento.',
  [T.uploadMessage]: 'Hai caricato ',
  [T.dropDocsMessage1]: 'Rilascia i documenti per iniziare il caricamento.',
  [T.dropDocsMessage2]: 'Trascina e rilascia i documenti qui.',
  [T.dropDocsRestriction]: 'Non puoi caricare questi tipi di documenti.',
  [T.dropDocsPartialRestriction]:
    'Alcuni tipi di documenti non validi sono stati respinti.',
  [T.failedFetchingDocuments]: 'Recupero dei documenti non riuscito.',
  [T.uploadDocsSuccess]: '{{count}} documento(i) caricato(i) con successo.',
  [T.uploadDocsWarningMessage]:
    '{{count}} documento(i) caricato(i) con successo e {{failed}} fallito(i). Riprovando...',
  [T.uploadDocSuccess]: '{{count}} documento caricato con successo!',
  [T.documentsUploadSuccess]: 'Documenti caricati con successo!',
  [T.searchDocuments]: 'Cerca Documenti',
  [T.enterTagName]: 'Inserisci il nome del tag',
  [T.folderCreateSuccess]: 'Cartella creata con successo!',
  [T.collectionCreateSuccess]: 'Raccolta creata con successo!',
  [T.workspaceCreateSuccess]: 'Spazio di lavoro creato con successo!',
  [T.folderCreateError]: 'Errore nella creazione della risorsa!',
  [T.folderCreateSuccessAccesError]:
    'Cartella creata con successo! Impossibile aggiornare i permessi di accesso.',
  [T.folderUpdateSuccessAccessError]:
    'Cartella aggiornata con successo! Impossibile aggiornare i permessi di accesso.',
  [T.selectTags]: 'Seleziona i Tag',
  [T.docTaggedSuccess]: 'Documenti etichettati con successo!',
  [T.docTaggedError]: "Errore nell'etichettatura dei documenti!",
  [T.docTagsDeleteSuccess]: 'Tag eliminati con successo!',
  [T.docTagsDeleteError]: 'Impossibile eliminare i tag!',
  [T.docTagsUpdatedSuccess]: 'Tag aggiornati con successo!',
  [T.docTagsUpdatedError]: 'Aggiornamento dei tag non riuscito!',
  [T.selectedTags]: 'Tag selezionati',
  [T.tags]: 'Tag',
  [T.selectUploadTags]: 'Seleziona i tag da applicare sui documenti caricati',
  [T.maxDocSize]:
    'La lunghezza del nome del documento "{{ name }}" supera il limite massimo di 200 caratteri.',
  [T.uploaded]: 'Caricato',
  [T.modifiedBy]: 'Modificato da',
  [T.syncedBy]: 'Sincronizzato da',
  [T.syncedOn]: 'Sincronizzato il',
  [T.syncing]: `Attendere mentre la cartella viene sincronizzata.`,
  [T.syncingFailed]: 'Tentativo di sincronizzazione non riuscito',
  [T.modifiedOn]: 'Modificato il',
  [T.status]: 'Stato',
  [T.documentDeletionFailed]: 'Eliminazione documento non riuscita.',
  [T.documentNotEmptyError]:
    'Non è consentito eliminare una cartella non vuota.',
  [T.uploadFolder]: 'Carica Cartella',
  [T.documentDeleteSuccess]: 'Documento eliminato con successo.',
  [T.documentsDeleteSuccess]: 'Documenti eliminati con successo.',
  [T.documentDeleteConfirmation]: 'Sei sicuro di voler eliminare il documento?',
  [T.collectionDeleteSuccess]: 'Collezione eliminata con successo!',
  [T.collectionDeleteConfirmation]:
    'Sei sicuro di voler eliminare la collezione?',
  [T.collectionDeletionFailed]: 'Eliminazione della collezione fallita.',
  [T.workspaceDeletionFailed]: 'Eliminazione dello spazio di lavoro fallita.',
  [T.workspaceDeleteSuccess]: 'Spazio di lavoro eliminato con successo!',
  [T.workspaceDeleteConfirmation]:
    'Sei sicuro di voler eliminare lo spazio di lavoro?',
  [T.multipleDocumentDeleteConfirmation]:
    'Sei sicuro di voler eliminare i documenti selezionati?',
  [T.nestedFolderDelete]:
    'Questa azione eliminerà tutte le cartelle, sottocartelle e file selezionati. Vuoi continuare?',
  [T.multipleFilesReprocessConfirmation]:
    'Sei sicuro/a di voler riprocessare il documento selezionato?',
  [T.genericConfirmation]: 'Sei sicuro?',
  [T.retrialFailed]:
    'Impossibile caricare alcuni documenti, riprova più tardi.',
  [T.deleteDocuments]: 'Elimina Documenti',
  [T.cancelUploadConfirmation]:
    'Sei sicuro di voler annullare il caricamento dei file rimanenti?',
  [T.retryFailedDocuments]: 'Riprova Documenti Non Riusciti',
  [T.processingFailed]: 'Elaborazione Non Riuscita',
  [T.processLastTransaction]: 'Elabora Ultima Transazione',
  [T.processAllTransactions]: 'Elabora Tutte le Transazioni',
  [T.documentDownloadError]: 'Download del documento non riuscito.',
  [T.destinationFolder]: 'Seleziona Cartella di Destinazione',
  [T.move]: 'Sposta',
  [T.moveDuplicateError]: 'File/Cartella già esistente.',
  [T.moveSuccess]: 'File/Cartella spostato con successo.',
  [T.moveFailed]: 'Errore nello spostamento di File/Cartella.',
  [T.reprocessSuccess]:
    'File in coda per il riprocessamento completato con successo.',
  [T.reprocessFailed]: 'Errore durante il riprocessamento dei file.',
  [T.reprocessCountInfo]: 'Riprocessamento di {{count}} file.',
  [T.syncSuccess]: 'Risorsa in coda per la sincronizzazione con successo.',
  [T.syncFailed]: 'Errore durante la sincronizzazione della risorsa.',
  [T.nameCharacterLengthError]: 'Il nome deve contenere da 1 a 200 caratteri.',
  [T.name]: 'Nome',
  [T.resourceSharingSuccessMessage]: 'Risorsa condivisa con successo.',
  [T.resourceSharingErrorMessage]:
    'Errore durante la condivisione della risorsa.',
  [T.userOrUserGroup]: 'Utente o Gruppo Utenti',
  [T.searchForUserOrUserGroup]: 'Cerca Utente o Gruppo Utenti',
  [T.message]: 'Messaggio',
  [T.addMessage]: 'Aggiungi un messaggio',
  [T.sendEmailNotificationToRecipients]:
    'Invia notifica via email ai destinatari',
  [T.advanced]: 'Avanzate',
  [T.keepManuallyAssignedRolesOnSubFoldersFiles]:
    'Mantieni ruoli assegnati manualmente su sottocartelle/file',
  [T.assignRoleToAllSubFoldersFiles]:
    'Assegna ruolo a tutte le sottocartelle/file',
  [T.assignRoleOnFilesOnly]: 'Assegna ruolo solo ai file',
  [T.noUserOrUserGroupHasAccessToResource]:
    'Nessun utente o gruppo utenti ha accesso alla risorsa',
  [T.noMatchingUsersOrUserGroupsFound]:
    'Nessun utente o gruppo utenti corrispondente trovato',
  [T.controllerRoleAssignmentToRegularUserAtWorkspace]:
    'Il ruolo selezionato non può essere assegnato agli utenti regolari negli spazi di lavoro.',
  [T.regularUserAssignmentToWorkspace]:
    'Gli utenti normali non possono essere designati come controller dello spazio di lavoro. Promuovi il tipo di account dalla pagina degli utenti.',
  [T.contollerAssignmentError]:
    'Non autorizzato a invitare utenti come controller negli spazi di lavoro.',
  [T.authorizedToRemoveLowTierAccountTypesOnly]:
    'Autorizzato a rimuovere assegnazioni solo per account di livello inferiore.',
  [T.containsCollection]: 'Contiene: {{count}} collezioni',
  [T.containsItems]: 'Contiene: {{count}} elementi',
  [T.createPermissionDenied]: 'Permesso di creazione non trovato',
  [T.uploadPermissionDenied]: 'Permesso di caricamento non trovato',
  [T.createCollection]: 'Crea una raccolta',
  [T.collectionPlaceholder]:
    "Organizza documenti su argomenti simili all'interno di una collezione",
  [T.createFolder]: 'Crea una cartella',
  [T.folderPlaceholder]:
    'Aggiungi contenuti per costruire il tuo dominio di conoscenza',
  [T.assignViewerSubtitle]: 'Interroga e leggi il contenuto.',
  [T.assignControllerSubtitle]:
    "Interroga, modifica e gestisci l'accesso al contenuto.",
  [T.assignContributorSubtitle]: 'Interroga, leggi e aggiungi al contenuto.',
  [T.emptyWorkspace]: 'Nessuno Spazio di Lavoro disponibile',
  [T.emptyCollection]: 'Nessuna Collezione disponibile',
  [T.workspaces]: 'Spazi di lavoro',
  [T.collections]: 'Collezioni',
  // Chat
  [T.Chat]: 'Chat',
  [T.chat]: 'chat',
  [T.createNewChat]: 'Nuova Chat',
  [T.send]: 'Invia',
  [T.queryPlaceholder]:
    'Chiedi a EmpowerGPT. Le risposte sono generate solo da documenti nella lingua selezionata.',
  [T.chatGptSlogan]: `Per assistenza nella creazione di contenuti, nella codifica, nella ricerca e nell'accesso alla conoscenza del mondo.`,
  [T.empowerGptSlogan]:
    'Per risposte alla luce di una base di conoscenza accessibile.',
  [T.chatConfigurations]: 'Configurazione Chat',
  [T.parameterDialogTitle]: 'Parametri',
  [T.parameterDialogSubtitle]:
    'Aggiorna i parametri della chat per cercare i documenti in modo efficiente',
  [T.updateParameters]: 'Aggiorna Parametri',
  [T.cancel]: 'Annulla',
  [T.submit]: 'Invia',
  [T.save]: 'Salva',
  [T.confirm]: 'Conferma',
  [T.parametersUpdateSuccess]: 'Parametri della chat aggiornati con successo!',
  [T.parametersUpdateError]:
    "Errore nell'aggiornamento dei parametri della chat!",
  [T.temperature]: 'Temperatura',
  [T.tokens]: 'Lunghezza massima (token)',
  [T.sequences]: 'Sequenze di stop',
  [T.probabilities]: 'Probabilità migliori',
  [T.frequency]: 'Penalità frequenza',
  [T.presence]: 'Penalità presenza',
  [T.chatRenameSuccess]: 'Chat rinominata con successo!',
  [T.chatRenameFailed]: 'Errore nel rinominare la chat!',
  [T.chatDeleteSuccess]: 'Chat eliminata con successo!',
  [T.chatDeleteFailed]: "Errore nell'eliminare la chat!",
  [T.generatingResponse]: 'Generazione risposta',
  [T.errorGeneratingResponse]:
    'Impossibile generare una risposta! Riprova per favore.',
  [T.messagesLoadingFailed]:
    'Errore nel recupero dei messaggi. Per favore, riprova.',
  [T.responseFailedMessage]:
    'Si è verificato un errore nella generazione di una risposta.',
  [T.responseStreamingFailedMessage]:
    'Si è verificato un errore nella trasmissione della risposta. Per favore, aggiorna la pagina.',
  [T.regenerateResponse]: 'Rigenera Risposta',
  [T.errorAddingReaction]: "Errore nell'aggiungere una reazione.",
  [T.errorUpdatingChatContext]:
    "Errore nell'aggiornare il contesto della chat.",
  [T.chatDeleteConfirmation]: 'Sei sicuro di voler eliminare la chat?',
  [T.samplePrompts]: 'Esempi di Promemoria',
  [T.capabilities]: 'Capacità',
  [T.limitations]: 'Limitazioni',
  [T.samplePrompt1]:
    'Quali verifiche devo effettuare prima di installare la pompa Etaprime L?',
  [T.samplePrompt2]:
    "Qual è l'intervallo ammissibile di viscosità del petrolio greggio per la centrale elettrica L27/38S?",
  [T.samplePrompt4]:
    'Linee guida per la creazione di una tabella pivot in Microsoft Excel.',
  [T.samplePrompt5]:
    'Consigli per la collaborazione e la comunicazione del team remoto.',
  [T.samplePrompt7]:
    'Suggerire strategie di ottimizzazione della catena di approvvigionamento.',
  [T.samplePrompt8]:
    "Best practice per l'identificazione dei rischi aziendali.",
  [T.capabilities1]:
    'Ottieni risposte alle tue domande basate esclusivamente sui documenti caricati.',
  [T.capabilities2]:
    "Ricorda il contesto delle domande precedenti all'interno di una chat.",
  [T.limitations1]:
    'Potrebbe non riuscire a confrontare attributi distribuiti in tutto il database.',
  [T.limitations2]:
    'La conoscenza è limitata allo scopo dei documenti caricati.',
  [T.includeChatHistory]:
    "Clicca per ricollegarti al contesto dell'ultima domanda.",
  [T.excludeChatHisory]: 'Clicca per scollegarti dal contesto della chat',
  [T.stickToDocs]: 'Resta sui Documenti: Limita la ricerca ai documenti aperti',
  [T.resourceUnavailable]: 'Risorsa non disponibile',
  [T.referenceDocumentDeleted]: 'Documento di riferimento eliminato',
  [T.disclaimerChatgpt]:
    'ChatGPT potrebbe commettere errori, si prega di verificare con le citazioni fornite. Offerto da GPT-4.',
  [T.askChatGptQuery]: 'Fai la tua domanda',
  [T.copy]: 'Copia',
  [T.copied]: 'Copiato',
  [T.errorCopyingResponse]: 'Errore durante la copia della risposta',
  [T.stickDocMessage]: 'Aprire il documento di riferimento per abilitare',
  [T.contentFilteringErrorMsg]: `La risposta è stata filtrata a causa del prompt che ha attivato il criterio di gestione dei contenuti di Azure. Modificare la richiesta e riprovare`,
  [T.generalErrorMsg]: `Il modello AI non è stato in grado di generare una risposta. Riprovare`,

  // Chat feedback
  [T.feedbackTitle]: 'Fornisci ulteriori feedback',
  [T.feedback1]: 'La risposta cita il documento sbagliato.',
  [T.feedback2]: 'La risposta esce dal campo dei documenti.',
  [T.feedback3]: 'La risposta è dannosa/insicura.',
  [T.feedback4]: 'La risposta non è utile.',
  [T.submitFeedback]: 'Invia feedback',
  [T.sources]: 'Sorgenti',
  [T.feedbackCommentPlaceholder]:
    'Cosa è andato storto con la risposta? Come può essere migliorata?',
  [T.feedbackValidationError]: 'Si prega di selezionare almeno 1 opzione.',

  // User/Roles/Permissions
  [T.rolesTitle]: 'Ruoli',
  [T.role]: 'Ruolo',
  [T.permissions]: 'Autorizzazioni',
  [T.users]: 'Utenti',
  [T.create]: 'Crea',
  [T.update]: 'Aggiorna',
  [T.add]: 'Aggiungi',
  [T.new]: 'Nuovo',
  [T.edit]: 'Modifica',
  [T.delete]: 'Elimina',
  [T.tag]: 'Tag',
  [T.upload]: 'Carica',
  [T.createRole]: 'Crea Ruolo',
  [T.updateRole]: 'Aggiorna Ruolo',
  [T.createUser]: 'Crea Utente',
  [T.updateUser]: 'Aggiorna Utente',
  [T.searchRole]: 'Cerca Ruolo Utente',
  [T.availableRole]: 'Ruolo Utente Disponibile',
  [T.assignedRole]: 'Ruolo Utente Assegnato',
  [T.confirmDeleteRole]: 'Sei sicuro di voler eliminare questo ruolo?',
  [T.confirmDeleteUser]: 'Sei sicuro di voler eliminare questo utente?',
  [T.confirmDeleteMultipleUsers]:
    'Sei sicuro di voler eliminare questi utenti?',
  [T.roleDeleteSuccess]: 'Ruolo eliminato con successo!',
  [T.deleteRow]: 'Elimina riga',
  [T.randomError]: 'Si è verificato un errore!',
  [T.errorDeletingRole]: "Errore durante l'eliminazione del ruolo.",
  [T.noRecordFound]: 'Nessun record trovato!',
  [T.roleUpdateSuccess]: 'Ruolo Aggiornato con Successo!',
  [T.roleUpdateError]: "Errore nell'aggiornamento del ruolo!",
  [T.roleCreateSuccess]: 'Ruolo Creato con Successo!',
  [T.roleCreateError]: 'Errore nella creazione del ruolo!',
  [T.userInviteSuccess]: 'Invito inviato via email.',
  [T.userInviteError]: "Errore durante l'invio dell'invito.",
  [T.userUpdateSuccess]: 'Utente aggiornato con successo!',
  [T.userUpdateFailed]: "Errore durante l'aggiornamento dell'utente.",
  [T.userDeleteSuccess]: 'Utente eliminato con successo!',
  [T.usersDeleteSuccess]: 'Utenti eliminati con successo!',
  [T.userDeleteError]: "Impossibile eliminare l'utente, per favore riprova!",
  [T.duplicateUserError]: "L'indirizzo email è già in uso",
  [T.duplicateFolderError]: 'File/Cartella già esistente.',
  [T.duplicateCollectionError]: 'La collezione esiste già.',
  [T.duplicateWorkspaceError]: 'Lo spazio di lavoro esiste già.',
  [T.renameSuccess]: 'Rinominato con successo!',
  [T.renameFailed]: 'Errore durante il rinominare!',
  [T.rename]: 'Rinomina',
  [T.enterRoleName]: 'Inserisci il Nome del Ruolo',
  [T.fullName]: 'Nome Completo',
  [T.enterFullName]: 'Inserisci il Nome Completo',
  [T.enterEmailAddress]: "Inserisci l'Indirizzo Email",
  [T.enterRoleDescription]: 'Inserisci la Descrizione del Ruolo',
  [T.roleName]: 'Nome del Ruolo',
  [T.invalidRoleName]: 'Nome del Ruolo non valido',
  [T.userName]: 'Nome Utente',
  [T.enterUserName]: 'Inserisci il Nome Utente',
  [T.moveSelectedRight]: 'Sposta selezionati a destra',
  [T.moveSelectedLeft]: 'Sposta selezionati a sinistra',
  [T.applyTags]: 'Applica Tag',
  [T.language]: 'Lingua',
  [T.dataLibrary]: 'Libreria Dati',
  [T.documentsStatus]: 'Stato Documenti Caricati',
  [T.statusFailedText]: 'Stato: Non riuscito',
  [T.statusUploadedText]: 'Stato: Caricato',
  [T.startDate]: 'Data di Inizio',
  [T.endDate]: 'Data di Fine',
  [T.selectStatus]: 'Seleziona Stato',
  [T.pages]: 'pagine',
  [T.addARole]: 'Aggiungi un Ruolo',
  [T.editRole]: 'Modifica Ruolo',
  [T.organizationAdminSubtitle]:
    'Può creare e assegnare spazi di lavoro, invitare amministratori/utenti, aggiungere ruoli personalizzati.',
  [T.workspaceAdminSubtitle]:
    'Può gestire interi spazi di lavoro, gruppi di utenti e aggiungere utenti regolari.',
  [T.regularUserSubtitle]:
    'Può chattare su documenti accessibili, caricare e condividere contenuti con altri utenti.',
  [T.licenseLimitReached]: 'Limite di licenza raggiunto',

  // workspace
  [T.workspaceName]: 'Nome Spazio di Lavoro',
  [T.collectionName]: 'Nome Raccolta',
  [T.workspaceColor]: 'Colore dello Spazio di Lavoro',
  [T.workspaceController]: 'Controller dello Spazio di Lavoro',
  [T.createWorkspace]: 'Crea Spazio di Lavoro',
  [T.updateWorkspace]: 'Aggiorna Spazio di Lavoro',
  [T.documentUpdateConfirmation]:
    'Rinomina lo spazio di lavoro e aggiorna il colore di sfondo',
  [T.backgroundColor]: 'Colore di Sfondo',
  [T.updatesOn]: 'Aggiornamenti',
  [T.updatedBy]: 'Aggiornato da',
  [T.workspaceUpdateSuccess]: 'Spazio di Lavoro aggiornato con successo!',
  [T.workspaceUpdateFailed]:
    "Errore nell'aggiornamento dello Spazio di Lavoro!",
  [T.sortBy]: 'Ordina Per',
  [T.lastUpdated]: 'Ultimo Aggiornamento',
  [T.ago]: 'fa',
  [T.day]: 'giorno',
  [T.days]: 'giorni',
  [T.hour]: 'ora',
  [T.hours]: 'ore',
  [T.min]: 'minuto',
  [T.mins]: 'minuti',
  [T.sec]: 'secondo',
  [T.secs]: 'secondi',
  [T.workSpaceDocumentsDropError]:
    'Aggiungi una raccolta per caricare i documenti al suo interno.',
  [T.createAWorkspace]: 'Crea uno Spazio di Lavoro',
  [T.newWorkspaceDescription]:
    'Crea una base di conoscenza separata per ogni dipartimento',
  [T.createACollection]: 'Crea una Raccolta',
  [T.publicDisclaimer]:
    'I contenuti in Pubblico sono condivisi con tutti gli utenti.',
  [T.privateDisclaimer]: `I contenuto dell'area di lavoro personale è accessibile solo all'utente.`,

  //no_data_messages
  [T.noDataNameSpaceMessage]:
    'Crea uno spazio di lavoro per iniziare a caricare dati al suo interno.',
  [T.noDataWorkspaceMessage]:
    'Carica una cartella o crea una raccolta per caricare dati al suo interno.',
  [T.noDataCollectionMessage]: 'Carica file/cartelle',
  [T.noDataNoPermissionMessage]:
    "Richiedi all'amministratore l'accesso ai documenti.",
  [T.noData]: 'Nessun Dato',
  [T.workSpaceInfoMessage]:
    "I nuovi spazi di lavoro possono essere creati solo dagli amministratori dell'organizzazione.",
  [T.moveDialogInfo]:
    'Il contenuto spostato manterrà le autorizzazioni originali della sorgente.',

  // File statuses
  [T.F_S_UP]: 'In corso',
  [T.F_S_RE]: 'Riprova',
  [T.F_S_PR]: 'Elaborato',
  [T.F_S_US]: 'Non supportato',
  [T.F_S_LLR]: 'Limite di licenza raggiunto',

  // User Groups
  [T.userGroupDeletedSuccessfully]: 'Gruppo Utenti Eliminato con Successo.',
  [T.userGroupsDeletedSuccessfully]: 'Gruppi Utenti Eliminati con Successo.',
  [T.userGroupCreatedSuccessfully]: 'Gruppo Utenti Creato con Successo.',
  [T.failedToCreateUserGroup]: 'Impossibile Creare il Gruppo Utenti.',
  [T.failedToDeleteUserGroup]: 'Impossibile Eliminare il Gruppo Utenti.',
  [T.failedToDeleteUserGroups]: 'Impossibile Eliminare i Gruppi Utenti.',
  [T.userGroupUpdatedSuccessfully]: 'Gruppo Utenti Aggiornato con Successo.',
  [T.failedToUpdateUserGroup]: 'Impossibile Aggiornare il Gruppo Utenti.',
  [T.addUserGroup]: 'Aggiungi un Gruppo Utenti',
  [T.editUserGroup]: 'Modifica Gruppo Utenti',
  [T.searchUsers]: 'Cerca Utenti',
  [T.createdBy]: 'Creato da',
  [T.userGroupDeleteConfirmationMsg]:
    'Sei sicuro di voler eliminare il/i Gruppo/i Utenti?',

  // Validations
  [T.maxLength]: '{{name}} non può superare {{length}} caratteri',
  [T.required]: '{{name}} è obbligatorio',
  [T.tagHelperText]:
    "Premi il tasto 'Invio' per creare un nuovo tag o selezionarne uno dalla lista.",

  // Usage
  [T.processedDocuments]: 'Documenti elaborati',
  [T.overallUsage]: 'Utilizzo complessivo',
  [T.aiUsage]: "Utilizzo dell'IA",
  [T.monthlyUsage]: 'Utilizzo mensile',
  [T.dailyUsage]: 'Utilizzo giornaliero',
  [T.processedDocumentsInfo]:
    'Numero stimato di parole rispetto ai documenti elaborati memorizzati nella libreria dati.',
  [T.tokenUsageInfo]:
    '{{consumed}} {{unit}} di documenti elaborati memorizzati nella libreria dati su un limite totale di {{total}} {{unit}}',
  [T.aiUsageInfo]: "Utilizzo dell'IA per chat e elaborazione documenti",
  [T.creditUsageInfo]:
    '{{consumed}} {{unit}} utilizzati su un limite totale di {{total}} {{unit}}',
  [T.credits]: 'Crediti',
  [T.consumed]: 'Consumato',
  [T.date]: 'data',
  [T.noCreditsUsedThisMonth]: 'Nessun credito utilizzato questo mese',

  // Error Messages
  [T.namespaceError]: 'Si è verificato un errore di spazio dei nomi.',
  [T.resourceNotFoundError]: 'Risorsa non trovata.',
  [T.noPermissionsAgainstResourceError]: 'Nessun permesso sulla risorsa.',
  [T.insufficientPrivileges]: "Permesso per eseguire l'azione non trovato.",
  [T.appRoleTemplateNotFoundError]: "Modello di ruolo dell'app non trovato.",
  [T.uploadTypeError]: 'Tipo di caricamento non valido fornito.',
  [T.transactionIdError]: 'Fornito un ID transazione non valido.',
  [T.uploadToRootError]: 'Errore durante il caricamento nella radice.',
  [T.uploadToFoundError]:
    "Non sei autorizzato a caricare file e cartelle nella cartella 'FOUND'.",
  [T.uploadToFileError]:
    'I file possono essere caricati solo in cartelle valide.',
  [T.fileUploadToWorkspaceError]:
    'I file non possono essere caricati a livello di spazio di lavoro.',
  [T.noFilesAttachedError]:
    'Seleziona file validi per iniziare il loro caricamento.',
  [T.fileStorageWriteError]: 'Errore di scrittura dello storage file.',
  [T.fileStorageReadError]: 'Errore di lettura dello storage file.',
  [T.requestedResourceNotFile]: 'La risorsa richiesta non è un file.',
  [T.fileStorageDeleteError]: 'Errore di eliminazione dello storage file.',
  [T.publicFolderDeleteError]: 'Impossibile eliminare la cartella pubblica.',
  [T.internalServerError]: 'Qualcosa è andato storto.',
  [T.invalidArgsError]: 'Argomenti non validi forniti.',
  [T.folderNotAllowedHere]: 'Non sei autorizzato a creare una cartella qui.',
  [T.filesPermission]: 'I file non sono consentiti qui.',
  [T.rolesInsufficientViewPrivileges]:
    'Non hai il diritto di visualizzare i ruoli.',
  [T.userGroupsInsufficientPrivileges]:
    'Non hai il diritto di visualizzare i gruppi utente.',
  [T.waCreateUserRestriction]:
    "L'amministratore dello spazio di lavoro può creare solo utenti regolari.",
  [T.readOnlyResourceUpdate]:
    'Non sei autorizzato a aggiornare una risorsa in sola lettura.',
  [T.readOnlyResourceDelete]:
    'Non sei autorizzato a eliminare una risorsa in sola lettura.',
  [T.rolesUniqueVoilation]: 'Ruolo con lo stesso nome già esistente.',
  [T.addPermissionsAgainstResourceError]:
    'Impossibile aggiungere permessi sulla risorsa.',
  [T.permissionsUpdatingErrorForRole]:
    'Impossibile aggiornare i permessi per la risorsa.',
  [T.insufficientCreatePrivileges]: 'Non hai i diritti per creare la risorsa.',
  [T.insufficientUpdatePrivileges]:
    'Non hai i diritti per aggiornare la risorsa.',
  [T.insufficientDeletePrivileges]:
    'Non hai i diritti per eliminare la risorsa.',
  [T.insufficientDeleteOneOrMorePrivileges]:
    'Non hai i diritti per eliminare una o più risorse.',
  [T.userGroupUniqueVoilation]:
    'Il gruppo utente con lo stesso nome già esiste.',
  [T.usersUniqueVoilation]: "L'utente esiste già.",
  [T.selfAssignmentOnResourceRestriction]:
    'Non puoi assegnarti a te stesso alla risorsa.',
  [T.ruAssignmentAsControllerRestriction]:
    'Gli utenti regolari non possono essere assegnati come controller.',
  [T.oaRemovalFromResourceDenied]:
    "All'amministratore dell'organizzazione non è consentito essere rimosso dalla risorsa.",
  [T.insufficientAssignmentPrivileges]:
    "Non hai abbastanza diritti per eseguire l'operazione.",
  [T.peerAccountAssignmentRestriction]:
    'Non ti è consentito rimuovere account dello stesso livello o di livello superiore.',
  [T.unauthorizedFilesError]: 'Non hai il permesso di caricare file/cartelle.',
  [T.messageOnEmptyFolderOrUnavailableFile]:
    'La chat non può essere avviata su risorse vuote o non disponibili.',
  [T.noSharedFilesExist]:
    "Si prega di caricare documenti nella libreria dati o richiedere accesso all'amministratore per iniziare a utilizzare l'interrogazione.",
  [T.itemDetailsNotFoundError]: 'Dettagli per questa risorsa non trovati.',
  [T.operationNotPermitted]: "Permesso per eseguire l'azione non trovato.",
  [T.uniqueFolderNameError]: 'Cartella già esistente.',
  [T.noResourcesShared]:
    'Sono ammessi solo documenti autorizzati. Si prega di caricare prima un documento.',

  // over limit messages

  [T.chatCreditLimitExceededOA]: `Limite mensile per i crediti AI raggiunto. Per assistenza immediata, contatta l'assistenza clienti all'indirizzo "support@empowergpt.ai"`,
  [T.chatCreditLimitExceeded]: `Limite mensile per i crediti AI raggiunto. Contatta il tuo amministratore.`,
  [T.fileCreditLimitExceededOA]: `
  Elaborazione dei documenti interrotta, limite mensile per i crediti AI raggiunto. Per assistenza immediata, contatta l'assistenza clienti all'indirizzo "support@empowergpt.ai".`,
  [T.fileCreditLimitExceeded]: `
  Elaborazione dei documenti interrotta, limite mensile per i crediti AI raggiunto. Contatta il tuo amministratore.`,
  [T.fileWordLimitExceededOA]: `Elaborazione del documento interrotta, è stato raggiunto il limite di parole per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio. Per ulteriore assistenza, contattare l'assistenza clienti all'indirizzo "support@empowergpt.ai".`,
  [T.fileWordLimitExceeded]: `Elaborazione del documento interrotta, è stato raggiunto il limite di parole per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio.`,

  [T.creditLimitExceeded90Percent]:
    'Oltre il 90% dei crediti AI è stato consumato.',
  [T.wordLimitExceeded90Percent]:
    'È stato raggiunto oltre il 90% del limite di parole allocate rispetto ai documenti elaborati archiviati',
  [T.wordLimitReached100Percent]: `È stato raggiunto il limite di parole allocate per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio. Per ulteriore assistenza, contattare l'assistenza clienti all'indirizzo "support@empowergpt.ai"`,

  // users invitations
  [T.inviteUsers]: 'Invita Utenti',
  [T.emailAddress]: 'Indirizzo Email',
  [T.userGroup]: 'Gruppo Utenti',
  [T.active]: 'Attivo',
  [T.invited]: 'Invitato',
  [T.invitationExpired]: 'Invito Scaduto',
  [T.inactive]: 'Inattivo',
  [T.resendInvite]: "Invia nuovamente l'invito",
  [T.deActivate]: 'Disattiva',
  [T.active]: 'Attiva',
  [T.inviteSentSuccessfully]: 'Invito inviato con successo!',
  [T.inviteSentFailed]: "Errore nell'invio dell'invito!",
  [T.userActivated]: 'Utente attivato con successo!',
  [T.userDeactivated]: 'Utente disattivato con successo!',
  [T.userActivationFailed]: "Errore nell'attivazione dell'utente!",
  [T.userDeactivationFailed]: "Errore nella disattivazione dell'utente!",
  [T.adUserNotFoundError]: 'Utente non trovato in AD.',
  [T.invalidEmail]: 'Inserisci un indirizzo email valido',

  // SharePoint
  [T.sharePointAuthError]:
    'Potresti non essere in grado di interagire con SharePoint a causa di un problema di connettività.',
  [T.sharepoint]: 'Importa da SharePoint',
  [T.sharepointModalTitle]: 'Seleziona una Cartella / Libreria',
  [T.sharepointModalSubtitle]:
    "Passare al sito desiderato e selezionare la cartella / libreria di destinazione per l'importazione",
  [T.availableSites]: 'Siti disponibili',
  [T.selectSiteMsg]:
    'Seleziona un sito per visualizzare i contenuti disponibili del sito',
  [T.siteContent]: 'Contenuto del sito',
  [T.showSiteContent]: 'Mostra contenuto del sito',
  [T.select]: 'Selezionare',
  [T.collectionSharepointPlaceholder]:
    'Crea una raccolta sincronizzata con SharePoint',
  [T.collectionSharepointDescription]:
    'Integra una cartella SharePoint nel tuo spazio di lavoro come raccolta sincronizzata',
  [T.resourceImportedSuccessfully]: 'Risorsa importata con successo.',
  [T.rootSite]: 'Siti principali',
  [T.sharePointSuccessModalTitle]: 'Cartella / Libreria collegata con successo',
  [T.sharePointSuccessModalLine1]:
    'Tutto a posto! La cartella / libreria selezionata insieme ai suoi contenuti sarà accessibile come una Collezione una volta completata la sincronizzazione dei dati.',
  [T.sharePointSuccessModalLine2]:
    'La visibilità della Collezione dipende esclusivamente dalle sue autorizzazioni in SharePoint.',
  [T.noSubSiteAvailable]: 'Nessuna Sotto-sito disponibile.',
  [T.noFolderFileAvailable]: 'Nessuna cartella / file disponibile.',
  [T.openInSharePoint]: 'Apri in SharePoint',
  [T.sharePointResourceDisclaimer]:
    'Le autorizzazioni di accesso sono gestite su SharePoint e sincronizzate in EmpowerGPT.',
  [T.Navigate]: 'Navigare',

  // SSO configuration
  [T.redirectUri]: 'URI di reindirizzamento',
  [T.confirmationStep1]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.signInWithMicrosoft]: 'Accedi con Microsoft (SSO)',
  [T.allowInvitingExternalUsers]: "Consenti l'invito a utenti esterni",
  [T.cloudDriveIntegration]: 'Integrazione del Cloud Drive',
  [T.syncFrequency]: 'Frequenza di sincronizzazione (minuti)',
  [T.contentImportFromSharepoint]: 'Importa contenuto da SharePoint',
  [T.letsStart]: 'Iniziamo',
  [T.next]: 'Il prossimo',
  [T.done]: 'Fatto',
  [T.welcomeScreenHeading1]: 'Passaggi per SSO',
  [T.welcomeScreenHeading2]: 'Configurazione',
  [T.registrationStep]: 'Registra "EmpowerGPT" in Microsoft Entra ID',
  [T.azureDetailsStep]:
    "Inserisci i dettagli dell'applicazione registrata su Microsoft Entra ID",
  [T.configurePermissions]:
    "Dai le autorizzazioni richieste contro gli APIs utilizzati all'applicazione registrata in Microsoft Entra ID",
  [T.registrationPoint1]: "In un'altra scheda, accedi a",
  [T.azurePortal]: 'Azure Portal',
  [T.roleAzurePortal]: 'come amministratore.',
  [T.registrationPoint2]:
    'Vai a "Microsoft Entra ID" (Azure Active Directory) > "App registrations" come amministratore.',
  [T.registrationPoint3]: 'Fai clic su "+ New registration".',
  [T.registrationPoint4]:
    'Inserisci "EmpowerGPT" come nome dell\'applicazione e seleziona i tipi di account supportati richiesti. (Consigliato: "Accounts in this organizational directory only").',
  [T.registrationPoint5]:
    'Nella sezione "Redirect URI" sul modulo di registrazione dell\'applicazione, seleziona "Web" come tipo e incolla il seguente URI di reindirizzamento nel campo corrispondente.',
  [T.confirmationStep1]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.redirectUriRequired]: 'URI di reindirizzamento è obbligatorio',
  [T.azureDetailsPoint1]:
    'Una volta registrata l\'applicazione, verrai reindirizzato alla pagina "Overview" dell\'applicazione registrata.',
  [T.azureDetailsPoint2]:
    'Copia l\'ID applicazione (client) e l\'ID directory (tenant) dalla pagina "Overview" e inseriscili in ciascuno dei campi sottostanti.',
  [T.azureDetailsPoint3]:
    'Vai a "Certificates and secrets" nella sezione "Manage" sull\'applicazione registrata in Microsoft Entra ID.',
  [T.azureDetailsPoint4]:
    'Seleziona la scheda "Client secrets" e fai clic sul pulsante "New client secret" per creare un nuovo segreto client. Inserisci "EmpowerGPT client secret" nella descrizione, imposta la scadenza a 24 mesi (consigliato) e fai clic su "add".',
  [T.azureDetailsPoint5]:
    'Copia il valore segreto e incollalo nel campo sottostante. I valori segreti del client non possono essere visualizzati. tranne subito dopo la creazione. Assicurati di salvare il valore sicuro quando viene creato prima di lasciare la pagina.',
  [T.clientIdRequired]: 'Client Id è obbligatorio',
  [T.labelClientId]: 'ID applicazione (client): ',
  [T.tenantIdRequired]: 'Tenant Id è obbligatorio',
  [T.labelTenantId]: 'Directory (tenant) Id:',
  [T.secretValueRequired]: 'Il valore segreto è obbligatorio',
  [T.labelSecret]: 'Valore segreto del client:',
  [T.enter]: 'Inserisci...',
  [T.permissionsStep1]:
    'Vai a "API permissions" nella sezione "Manage" sull\'applicazione registrata in Microsoft Entra ID.',
  [T.permissionsStep2]:
    'Fai clic sul pulsante "+ Add a permission" e seleziona "Microsoft Graph" in Microsoft APIs.',
  [T.permissionsStep3]:
    'Seleziona "Application permissions" per dare le seguenti autorizzazioni e conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.permissionsStep4]: 'User.Read.All in User.',
  [T.permissionsStep5]: 'Group.Read.All in Group.',
  [T.permissionsStep6]: 'GroupMember.Read.All in GroupMember.',
  [T.permissionsStep7]:
    'Ripeti il passaggio 2 e seleziona "Delegated permissions" per dare l\'autorizzazione "profile". Conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.permissionsStep8]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.successDialogTitle]: 'SSO configurato con successo',
  [T.successDialogDescription]:
    "SS0 è stato configurato con successo contro gli utenti all'interno della directory collegata.",
  [T.clickHere]: 'Clicca qui',
  [T.toLogout]: ' per disconnettersi e testare la configurazione.',
  // Resource Provider config
  [T.resourceProviderWelcomeTitle]:
    "Passaggi per la configurazione dell'importazione da SharePoint",
  [T.resourceProviderTitle1]:
    'Carica il certificato generato nell\'applicazione registrata "EmpowerGPT" su Microsoft Entra ID',
  [T.resourceProviderStep1Instruction1]:
    "Incolla l'URL di base per il sito SharePoint della tua organizzazione di seguito. (L'URL di base per SharePoint Online viene fornito in base all'immissione durante la configurazione dell'abbonamento a Microsoft 365. Ad esempio, se il nome della tua organizzazione è Contoso, il sito principale di SharePoint Online sarà https://contoso.sharepoint.com.).",
  [T.resourceProviderStep1Instruction2]:
    'Fai clic sul pulsante sottostante per scaricare il certificato richiesto.',
  [T.resourceProviderStep1Instruction3]:
    "In un'altra scheda, accedi al portale di Azure come amministratore.",
  [T.resourceProviderStep1Instruction4]:
    'Vai a "Microsoft Entra ID" (Azure Active Directory) > "App registrations" > "All applications" > "EmpowerGPT".',
  [T.resourceProviderStep1Instruction5]:
    'Seleziona l\'applicazione e vai a "Certificates and secrets" nella sezione "Manage".',
  [T.resourceProviderStep1Instruction6]:
    'Seleziona la scheda "Certificates" e fai clic su "Upload certificate". Carica il certificato generato sopra, scaricato sul tuo computer.',
  [T.resourceProviderStep1Instruction7]:
    'Inserisci una descrizione appropriata e clicca sul pulsante "Aggiungi" per aggiungere il certificato.',
  [T.resourceProviderTitle2]:
    "Concedi le autorizzazioni richieste per le API utilizzate all'applicazione registrata in Microsoft Entra ID",
  [T.resourceProviderStep2Instruction1]:
    'Vai a "API permissions" nella sezione "Manage" dell\'applicazione registrata in Microsoft Entra ID.',
  [T.resourceProviderStep2Instruction2]:
    'Clicca su "+ Add a permission" e seleziona "Microsoft Graph" sotto le Microsoft APIs.',
  [T.resourceProviderStep2Instruction3]:
    'Seleziona "Application permissions" per concedere le seguenti autorizzazioni e conferma cliccando su "add permission" in basso.',
  [T.resourceProviderStep2Instruction5]: 'Sites.Read.All.',
  [T.resourceProviderStep2Instruction6]: 'Files.Read.All.',
  [T.resourceProviderStep2Instruction7]:
    'Ripeti il passaggio 2 e seleziona "Delegated permissions" per concedere le seguenti autorizzazioni. Conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.resourceProviderStep2Instruction8]:
    'Fai clic sul pulsante "+ Add a permission" e seleziona "SharePoint" sotto Microsoft APIs per dare le seguenti "Application permission". Fai clic su "add permission" per confermare.',
  [T.sharepointConfigurationSuccessTitle]:
    'Importazione da SharePoint configurata',
  [T.sharepointConfigurationSuccessDescription1]:
    'Ora è possibile importare contenuti dai siti SharePoint in EmpowerGPT come collezioni.',
  [T.sharepointConfigurationSuccessDescription2]:
    'La visibilità dei contenuti importati sarà basata sulle autorizzazioni in SharePoint.',
  [T.downloadCertificate]: 'Scarica il certificato',
  [T.invalidSharePointUrl]: 'URL di SharePoint non valido fornito.',
  [T.identityProviderEnabledSuccessfully]:
    'Microsoft (SSO) è stato abilitato correttamente.',
  [T.resourceProviderEnabledSuccessfully]:
    'SharePoint abilitato correttamente.',
  [T.identityProviderDisabledSuccessfully]:
    'Microsoft (SSO) è stato disabilitato correttamente.',
  [T.resourceProviderDisabledSuccessfully]:
    'SharePoint disabilitato correttamente.',
  [T.identityProviderUpdateFailed]: 'Impossibile aggiornare Microsoft (SSO).',
  [T.resourceProviderUpdateFailed]: 'Impossibile aggiornare SharePoint.',
  [T.identityProviderCreationFailed]: "Errore nell'abilitare Microsoft (SSO).",
  [T.resourceProviderCreationFailed]: "Errore nell'abilitare SharePoint.",
  [T.disabledToolTipMicrosoftSSO]: 'Abilita Microsoft SSO per configurare.',
  [T.requiredCheckbox]: 'Questo campo è obbligatorio',
  [T.adminConsent]:
    'Assicurati che sia stata fornita l\'autorizzazione dell\'amministratore per le autorizzazioni che la richiedono, indicate da un "Yes" nella colonna "Admin consent required" della tabella "Configured permissions".',

  //Internal Analytics
  [T.analytics]: 'analitica',
  [T.questions]: 'Domande',
  [T.dislikes]: 'Dispiaceri',

  // User Favourites
  [T.favourites]: 'Preferiti',
  [T.markFavourite]: 'Segna come preferito',
  [T.unmarkFavourite]: 'Deseleziona come preferito',
  [T.markedFavourite]: 'Contrassegnato come preferito',
  [T.unmarkedFavourite]: 'Non contrassegnato come preferito',
  [T.markFavouriteError]: 'Errore nel contrassegnare come preferito',
  [T.unmarkFavouriteError]: 'Errore durante la demarcazione come preferita',
  [T.favouriteLimitReached]: "Limite dei preferiti dell'utente raggiunto",

  //User Onboarding
  [T.welcomeText]: `Benvenuti a`,
  [T.welcomeText1]: `EmpowerGPT è una piattaforma di intelligenza artificiale creata per le aziende. Chatta in modo sicuro con LLM all'avanguardia che incorporano le vostre conoscenze aziendali e mondiali.`,
  [T.welcomeText2]: `Ampliate la base di conoscenze della vostra azienda caricando o importando dati da applicazioni integrate.`,
  //Chat
  [T.gotIt]: `Capito`,
  [T.onNext]: 'Fare clic su Avanti per continuare.',
  [T.chatStep1Title]: 'Chat: Selezionare il dominio di conoscenza richiesto.',
  [T.chatStep1Description]: `Scegliete come volete che EmpowerGPT risponda. Attenetevi alla conoscenza aziendale per ottenere risposte alla luce di documenti aziendali accessibili. Oppure passate alla conoscenza del mondo per ottenere assistenza nella creazione di contenuti, nella codifica, nella ricerca e nell'accesso alla conoscenza del mondo.`,
  [T.chatStep2Title]: 'Chat: Digitare la domanda.',
  [T.chatStep2Description]: `Utilizzate il campo di input sottostante per inserire le vostre domande e premete il pulsante di invio sulla destra. Il confronto con le domande e le risposte precedenti è limitato all'interno di una chat.`,
  [T.chatStep3Title]: 'Chat: Rivedere i fatti.',
  [T.chatStep3Description]: `Le risposte alle chat nella conoscenza aziendale hanno riferimenti citati alla fine. Si raccomanda di convalidare l'autenticità della risposta consultando i riferimenti.`,
  [T.chatQuery]: `Quali sono i diversi tipi di conto in EmpowerGPT?`,
  //Data Library
  [T.documentStep1Title]: 'Caricare: Navigare nella Libreria dati.',
  [T.documentStep1Description]: `Data Library contiene spazi di lavoro che sono stati condivisi con l'utente. Gli spazi di lavoro classificano i documenti all'interno di un'organizzazione.`,
  [T.documentStep2Title]: `Caricare: Selezionare l'area di lavoro richiesta.`,
  [T.documentStep2Description]: `Le azioni disponibili su un'area di lavoro si basano sul ruolo che vi è stato assegnato. "Il mio spazio di lavoro" è uno spazio di lavoro personale, con il quale è possibile aggiungere documenti e chattare.`,
  [T.documentStep3Title]: `Caricare: Creare una raccolta.`,
  [T.documentStep3Description]: `Le raccolte classificano ulteriormente i documenti all'interno di ogni area di lavoro. Create la vostra raccolta all'interno dell'area di lavoro My per caricare i documenti al suo interno.`,
  [T.documentStep4Title]: `Caricare: Selezionare i documenti da caricare`,
  [T.documentStep4Description]: `EmpowerGPT supporta un'ampia varietà di formati di documenti che gli utenti possono aggiungere alla base di conoscenza, tra cui PDF (anche documenti scansionati), documenti Word, diapositive Powerpoint e file Excel.`,
  [T.documentStep5Title]: 'Caricamento: Capire lo stato del documento',
  [T.documentStep5Description]: `Una volta caricati, i documenti vengono messi in coda per l'elaborazione da parte dell'AI. Una volta contrassegnati come “Eseguiti”, gli utenti che hanno accesso a questi documenti possono chattare con loro.`,

  // Invite User
  [T.inviteUserStep1Title]: 'Invitare: Aprire il menu',
  [T.inviteUserStep1Description]: `I pulsanti del menu contengono i collegamenti alle pagine necessarie per la configurazione, la gestione dell'applicazione e dell'account.`,
  [T.inviteUserStep2Title]: 'Invito: Andare alla pagina degli utenti',
  [T.inviteUserStep2Description]: `La pagina Utenti contiene l'elenco di tutte le persone attualmente presenti sulla piattaforma empowerGPT. Gli amministratori possono invitare gli utenti da questa pagina.`,
  [T.inviteUserStep3Title]: `Invito: Digitare l'e-mail dell'invitato`,
  [T.inviteUserStep3Description]: `Digitare i messaggi di posta elettronica degli invitati, selezionare il tipo di account e gli eventuali gruppi di utenti prima di inviare l'invito.`
};

export default itJson;
