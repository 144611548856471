import { FC, useState, createContext, useEffect, useRef } from 'react';
import { useDispatch, RootState, useSelector } from 'src/redux/store';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import { decryptResponse } from 'src/services/utils';
import { setAlert } from 'src/redux/slices/snackbar';
import { ALERT } from 'src/types/enum';
import { enc } from 'crypto-js';
import { KEY_DB64 } from 'src/utils/encryption';
import logger from 'src/utils/logger';
import { StringKeys } from 'src/types/base';
import { useLocation } from 'react-router-dom';

type AzureWebPubSubUserContextType = {
  connectionId: string;
  processedData: StringKeys;
  syncedData: StringKeys;
  resetContext: () => void;
};

export const AzureWebPubSubUserContext =
  createContext<AzureWebPubSubUserContextType>(
    {} as AzureWebPubSubUserContextType
  );

export const AzureWebPubSubUserProvider: FC = ({ children }) => {
  const [prevLocation, setPrevLocation] = useState(null);
  const [pubsubClient, setPubsubClient] = useState<any>(null);
  const [connectionId, setConnectionId] = useState<string>();
  const { azureWebPubSubInfo } = useSelector((state) => state.auth);
  const [processedData, setProcessedData] = useState<StringKeys>({});
  const [syncedData, setSyncedData] = useState<StringKeys>({});
  const { tenant_info } = useSelector((state: RootState) => state.library);
  const isMounted = useRef(true);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const wpsClient = new WebPubSubClient(azureWebPubSubInfo.url);
    wpsClient.on('connected', (e) => {
      logger.log('connected user wps connection', e.connectionId);
      setConnectionId(e.connectionId);
    });
    wpsClient.on('disconnected', (e) => {
      logger.log('disconnected user wps connection', e.connectionId);
      setConnectionId(undefined);
    });
    wpsClient.on('server-message', ({ message }: any) => {
      const KEY_BDB64 = enc.Utf8.parse(KEY_DB64.slice(0, 32));
      const data = decryptResponse(message, KEY_BDB64);
      if (data?.event_type === 'REFRESH_TOKEN') {
        dispatch(
          setAlert({
            msg: 'Account type updated; Reloading application in 3 seconds.',
            type: ALERT.WARNING
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    });

    setPubsubClient(wpsClient);
    wpsClient.on('group-message', ({ message }: any) => {
      onGroupMessage(message);
    });

    wpsClient.start();
    return () => {
      wpsClient?.leaveGroup(
        `data-library-${tenant_info['default_namespace_id']}`
      );
      isMounted.current = false;
      wpsClient?.stop();
    };
  }, [azureWebPubSubInfo.url]);

  useEffect(() => {
    if (pubsubClient) {
      let groupName = `data-library-${tenant_info['default_namespace_id']}`;
      const wasDataLibrary = prevLocation && isRelevantUrl(prevLocation);
      const isDataLibrary = isRelevantUrl(location.pathname);

      if (wasDataLibrary && !isDataLibrary) {
        pubsubClient?.leaveGroup(groupName);
      } else if (!wasDataLibrary && isDataLibrary) {
        pubsubClient?.joinGroup(groupName);
      }
      setPrevLocation(location.pathname);
    }
  }, [pubsubClient, location.pathname]);

  const isRelevantUrl = (path) => {
    return path.includes('collection') || path.includes('resources');
  };

  const resetContext = () => {
    if (isMounted.current) {
      setProcessedData({});
      setSyncedData({});
    }
  };

  const onGroupMessage = (message) => {
    const isDataLibrary = isRelevantUrl(location.pathname);

    if (isDataLibrary) {
      const KEY_BDB64 = enc.Utf8.parse(KEY_DB64.slice(0, 32));
      const data = decryptResponse(message, KEY_BDB64);

      if (data?.event_type == 'FILE_PROCESSING_CSC_EVENT') {
        setProcessedData(data?.state_change?.[0]);
      } else if (data?.event_type == 'FILE_SYNC_CSC_EVENT') {
        setSyncedData(data?.state_change);
      }
    }
  };

  return (
    <AzureWebPubSubUserContext.Provider
      value={{
        connectionId,
        processedData,
        resetContext,
        syncedData
      }}
    >
      {children}
    </AzureWebPubSubUserContext.Provider>
  );
};
