import React, { CSSProperties, FC } from 'react';
import {
  Box,
  TextField as MuiTextField,
  Typography,
  useTheme
} from '@mui/material';

interface TextProps {
  [z: string]: any;
  label?: string;
  placeholder?: string;
  size?: 'medium' | 'small';
  required?: boolean;
  variant?: 'outlined' | 'filled' | 'standard';
  defaultValue?: string;
  value?: string;
  style?: any;
  onChange: (event: any) => void;
  customLabel?: boolean;
  fontSize?: CSSProperties['fontSize'];
  height?: CSSProperties['height'];
}

const TextField: FC<TextProps> = React.forwardRef<
  HTMLDivElement | null,
  TextProps
>(
  (
    {
      label,
      placeholder,
      size,
      onChange,
      required,
      variant,
      value,
      defaultValue,
      customLabel,
      fontSize,
      height,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    let extra: { value?: string; defaultValue?: string; [z: string]: any } = {
      ...rest
    };

    if (defaultValue) {
      extra.defaultValue = defaultValue;
    } else {
      extra.value = value;
    }

    return (
      <Box>
        {customLabel && (
          <Typography
            sx={{
              marginBottom: '5px',
              marginLeft: '2px',
              color: theme.colors.custom.labelColor,
              fontSize: fontSize || '13px'
            }}
            gutterBottom
          >
            {label}
          </Typography>
        )}
        <MuiTextField
          label={customLabel ? null : label}
          placeholder={placeholder}
          size={size}
          onChange={onChange}
          required={required}
          variant={variant}
          {...extra}
          ref={ref}
          InputLabelProps={{
            shrink: customLabel ? false : true,
            style: { fontSize: 12 }
          }}
          className={rest.className}
          InputProps={{ style: { fontSize, height } }}
          sx={{
            ...rest.sx,
            height
          }}
          {...rest}
        />
      </Box>
    );
  }
);

TextField.defaultProps = {
  placeholder: '',
  size: 'small',
  required: false,
  defaultValue: '',
  value: '',
  label: ''
};

export default TextField;
