import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce, uniqWith, isEqual } from 'lodash';
import get from 'lodash/get';
import { Box, Typography, useTheme, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StringKeys } from 'src/types/base';

interface SearchableAutoCompleteProps {
  searchApi: any;
  label?: string;
  placeholder?: string;
  optionLabel: string;
  multiple?: boolean;
  required?: boolean;
  customLabel?: boolean;
  onChange: (event: any, value: any) => void;
  valueKey: string;
  value?: string;
  [z: string]: any;
  searchApiParams?: StringKeys;
  customValidation?: (value: StringKeys | string) => boolean;
  autoSelect?: boolean;
}

const SearchableAutoComplete = React.forwardRef<
  HTMLDivElement | null,
  SearchableAutoCompleteProps
>(
  (
    {
      searchApi,
      label = '',
      placeholder,
      optionLabel,
      multiple = false,
      required = false,
      customLabel = false,
      onChange,
      valueKey,
      value,
      searchApiParams,
      customValidation,
      ...rest
    }: SearchableAutoCompleteProps,
    ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [fetchData, { data, isFetching }] = searchApi();
    const [selectValue, setSelectValue] = useState<any>(multiple ? [] : '');
    const [isFieldFocused, setIsFieldFocused] = useState(false);

    const toggleFieldFocus = () => {
      setIsFieldFocused(!isFieldFocused);
    };

    const searchData = useCallback(
      debounce(async (s: string) => {
        try {
          await fetchData({ params: { s, ...searchApiParams } }).unwrap();
        } catch (error) {
          setOptions([]);
        }
      }, 300),
      []
    );

    const handleInputChange = async (event) => {
      const inputValue = event.target.value;

      if (inputValue === '') {
        if (multiple) setOptions([...value]);
        else setOptions([]);
        return;
      }
      searchData(inputValue);
    };

    const handleSelectionChange = (_, selectedValues) => {
      if (customValidation) {
        if (customValidation(selectedValues[selectedValues.length - 1])) {
          setSelectValue(() => selectedValues);
        } else {
          return;
        }
      } else if (!customValidation) {
        setSelectValue(() => selectedValues);
      }
      onChange(_, selectedValues);
    };

    useEffect(() => {
      if (data && !isFetching) {
        if (multiple) {
          setOptions(uniqWith([...value, ...(data as any)], isEqual));
        } else setOptions(data as any);
      }
    }, [data, isFetching]);

    useEffect(() => {
      if (!value) {
        setSelectValue(multiple ? [] : null);
        setOptions([]);
      } else {
        setSelectValue(value);
      }
    }, [value]);

    return (
      <Box>
        {customLabel && (
          <Typography
            sx={{
              marginBottom: '5px',
              marginLeft: '2px',
              color: theme.colors.custom.labelColor,
              fontSize: '13px'
            }}
            gutterBottom
          >
            {label}
          </Typography>
        )}
        <Autocomplete
          {...rest}
          ref={ref}
          limitTags={1}
          size="small"
          autoHighlight
          id="reusable-autocomplete"
          options={options}
          open={open}
          loading={isFetching}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return get(option, optionLabel) || '';
          }}
          isOptionEqualToValue={(option, _value) => {
            if (option instanceof Object && _value instanceof Object) {
              return (
                get(option, valueKey || 'id') === get(_value, valueKey || 'id')
              );
            }
            return typeof option === 'string'
              ? option === value
              : option?.[valueKey || 'id'] === value?.[valueKey || 'id'];
          }}
          multiple={multiple}
          onChange={handleSelectionChange}
          componentsProps={{
            clearIndicator: {
              sx: {
                mr: 0.01,
                color: theme.colors.alpha.black[100],
                backgroundColor: 'transparent',
                '&:hover': {
                  color: theme.colors.alpha.black[100],
                  backgroundColor: theme.colors.alpha.black[10]
                }
              }
            }
          }}
          value={selectValue}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={customLabel ? '' : label}
                variant="outlined"
                placeholder={
                  selectValue?.length && isFieldFocused ? '' : placeholder
                }
                onChange={handleInputChange}
                onFocus={toggleFieldFocus}
                onBlur={toggleFieldFocus}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            );
          }}
          noOptionsText={t(T.typeToSearch)}
        />
      </Box>
    );
  }
);

export default SearchableAutoComplete;
