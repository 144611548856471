export const TRANSLATION_CONSTANTS = {
  // General
  empowerGPT: 'EmpowerGPT',
  knowledgeBaseChat: 'Knowledge Base Chat',
  enterprise: 'Enterprise',
  worldKnowledge: 'World Knowledge',
  generalChat: 'General Chat',
  languageSwitcher: 'Switch Language',
  logout: 'Logout',
  profile: 'Profile',
  uploadImport: 'Upload / Import',
  documents: 'Documents',
  conversations: 'Conversations',
  valuableInsights: 'Quickly extract valuable insights',
  uploadYourDocuments: 'Upload documents',
  clearFilters: 'Reset',
  resetPassword: 'Change Password',
  userGroups: 'User Groups',
  userGroupName: 'User group name',
  accountType: 'Account Type',
  email: 'Email',
  startChat: 'Start Chat',
  createdWithTime: 'Created {{time}} ago',
  modifiedWithTime: 'Modified {{time}} ago',
  syncedWithTime: 'Synced {{time}} ago',
  rolesAndPermissions: 'Roles And Permissions',
  selectItemsToDelete: 'Select Items to delete',
  selectItemsToTag: 'Select items to tag',
  selectItemsToMove: 'Select items to move',
  noPermissionToDelete: 'Do not have permission to delete',
  noPermissionToView: 'Do not have permission to view',
  noPermissionToMoveHere: 'Do not have permission to move here',
  noPermissionToDownload: 'Do not have permission to download',
  tagDisabledForWorkspaceChat:
    'Tags functionality is not available for workspace chats',
  typeToSearch: 'Type to search',
  usage: 'Usage',
  disclaimerEgpt:
    'EmpowerGPT may make mistake, please consider verifying with the provided citations.',
  chatGPT: 'ChatGPT',
  reprocess: 'Reprocess',
  selectItemsToReprocess: 'Select items to reprocess',
  fileProcessing: 'File Processing',
  ok: 'OK',
  goBack: 'Go Back',
  settings: 'Settings',
  version: 'Version',
  llmsSettingsHeading: 'AI Models Available in Chat',
  selectModels: 'Select Models',
  configure: 'Configure',
  tenantKnowledgeBase: '{{tenant}} knowledge',
  selected: 'Selected',

  //Filters
  search: 'Search',
  accountTypeSelect: 'Select Account Type',

  // Documents
  uploadDocs: 'Upload Documents',
  tagDocuments: 'Tag Documents',
  allDocuments: 'All documents',
  docName: 'Document name',
  uploadedBy: 'Uploaded By',
  modifiedBy: 'Modified By',
  syncedBy: 'Synced By',
  syncedOn: 'Synced On',
  syncing: 'Please wait while the folder is being synced.',
  syncingFailed: 'Sync attempt failed',
  uploadedOn: 'Uploaded On',
  modifiedOn: 'Modified On',
  actions: 'Actions',
  view: 'View',
  share: 'Share',
  sync: 'Sync',
  sharedWith: 'Shared with',
  invite: 'Invite',
  details: 'Details',
  documentType: 'Document Type',
  size: 'Size',
  length: 'Length',
  description: 'Description',
  rowsPerPage: 'Rows per page',
  uploadTimeAndDateWithUser: 'Uploaded {{time}} by {{user}}',
  download: 'Download',
  uploadProgress: 'Upload Progress',
  document: 'document',
  docsDropMessage: 'Drop the documents to start uploading',
  uploadDocFailed: `Failed to upload documents.`,
  uploadDocsFailed: `Failed to upload document.`,
  uploadMessage: `You have uploaded `,
  dropDocsMessage1: `Drop the documents to start uploading`,
  dropDocsMessage2: 'Drag & drop documents here',
  dropDocsRestriction: 'You cannot upload these document types.',
  unauthorizedFilesError: "You don't have permission to upload files/folders.",
  dropDocsPartialRestriction: 'Some invalid document types were rejected.',
  failedFetchingDocuments: 'Failed to fetch documents.',
  uploadDocsSuccess: '{{count}} document(s)  uploaded successfully.',
  uploadDocsWarningMessage:
    '{{count}} document(s) uploaded successfully and {{failed}} failed. Retrying...',
  uploadDocSuccess: '{{count}} document uploaded successfully!',
  documentsUploadSuccess: 'Documents uploaded successfully!',
  searchDocuments: 'Search Documents',
  enterTagName: 'Enter tag name',
  folderCreateSuccess: 'Folder created successfully!',
  workspaceCreateSuccess: 'Workspace created successfully!',
  collectionCreateSuccess: 'Collection created successfully!',
  folderCreateError: 'Error creating resource!',
  folderCreateSuccessAccesError:
    'Folder created successfully!Failed to update access permission.',
  folderUpdateSuccessAccessError:
    'Folder updated successfully! Failed to update access permission.',
  selectTags: 'Select Tags',
  docTaggedSuccess: 'Documents tagged successfully!',
  docTaggedError: 'Error tagging documents!',
  docTagsDeleteSuccess: 'Tags deleted successfully!',
  docTagsDeleteError: 'Failed to delete tags!',
  docTagsUpdatedSuccess: 'Tags updated successfully!',
  docTagsUpdatedError: 'Failed to update tags!',
  selectedTags: 'Selected Tags',
  tags: 'Tags',
  selectUploadTags: 'Select tags to apply on uploaded documents',
  maxDocSize:
    'Document name length of "{{ name }}" exceeds the maximum limit of 200.',
  uploaded: 'Uploaded',
  status: 'Status',
  documentDeletionFailed: 'Failed to delete document.',
  documentNotEmptyError: 'Deleting a non-empty folder is not allowed.',
  uploadFolder: 'Upload Folder',
  documentDeleteSuccess: 'Document deleted successfully!',
  documentsDeleteSuccess: 'Documents deleted successfully!',
  documentDeleteConfirmation: 'Are you sure you want to delete the document?',
  collectionDeleteSuccess: 'Collection deleted successfully!',
  collectionDeleteConfirmation:
    'Are you sure you want to delete the collection?',
  collectionDeletionFailed: 'Failed to delete collection.',
  workspaceDeletionFailed: 'Failed to delete workspace.',
  workspaceDeleteSuccess: 'Workspace deleted successfully!',
  workspaceDeleteConfirmation: 'Are you sure you want to delete the workspace?',
  multipleDocumentDeleteConfirmation:
    'Are you sure you want to delete the selected documents?',
  nestedFolderDelete:
    'This action will delete all selected folders, subfolders, and files. Do you want to continue?',
  multipleFilesReprocessConfirmation:
    'Are you sure you want to reprocess the selected document?',
  genericConfirmation: 'Are you sure?',
  retrialFailed: 'Failed to upload some documents please try again later.',
  deleteDocuments: 'Delete Documents',
  cancelUploadConfirmation:
    'Are you sure you want to cancel uploading the remaining files?',
  retryFailedDocuments: 'Retry Failed Documents',
  processingFailed: 'Processing Failed',
  processLastTransaction: 'Process Last Transaction',
  processAllTransactions: 'Process All Transactions',
  documentDownloadError: 'Failed to download document.',
  destinationFolder: 'Select Destination Folder',
  move: 'Move',
  moveDuplicateError: 'File/Folder already exists.',
  moveSuccess: 'File/Folder moved successfully.',
  moveFailed: 'Error moving File/Folder.',
  reprocessSuccess: 'Files queued for reprocessing successfully.',
  reprocessFailed: 'Error reprocessing files.',
  reprocessCountInfo: 'Reprocessing {{count}} files.',
  syncSuccess: 'Resource queued for syncing successfully.',
  syncFailed: 'Error syncing resource.',
  nameCharacterLengthError: 'Name should be between 1 to 200 characters.',
  name: 'Name',
  resourceSharingSuccessMessage: 'Resource shared successfully.',
  resourceSharingErrorMessage: 'Error while sharing resource.',
  userOrUserGroup: 'User or User Group',
  searchForUserOrUserGroup: 'Search for User or User Group',
  message: 'Message',
  addMessage: 'Add a message',
  sendEmailNotificationToRecipients: 'Send email notification to recipients',
  advanced: 'Advanced',
  keepManuallyAssignedRolesOnSubFoldersFiles:
    'Keep already assigned roles on sub folders/files',
  assignRoleToAllSubFoldersFiles: 'Assign role to all sub folders/files',
  assignRoleOnFilesOnly: 'Assign role on files only',
  noUserOrUserGroupHasAccessToResource:
    'No User or User group has access to the Resource.',
  noMatchingUsersOrUserGroupsFound: 'No matching users or user groups found',
  controllerRoleAssignmentToRegularUserAtWorkspace:
    'Selected role can not be assigned to Regular Users at workspaces.',
  regularUserAssignmentToWorkspace:
    'Regular users can not be made a workspace controller. Promote account type from users page.',
  contollerAssignmentError:
    'Not authorised to invite users as controllers on workspaces.',
  authorizedToRemoveLowTierAccountTypesOnly:
    'Authorized to remove assignments for lower-tier account type only.',
  containsCollection: 'Contains: {{count}} Collections',
  containsItems: 'Contains: {{count}} Items',
  createPermissionDenied: 'Permission to create not found',
  uploadPermissionDenied: 'Permission to upload not found',
  createCollection: 'Create a Collection',
  collectionPlaceholder:
    'Organize documents around similar topics with in a collection',
  createFolder: 'Create a Folder',
  folderPlaceholder: 'Add content to build up your knowledge domain',
  assignViewerSubtitle: 'Query and read the content.',
  assignControllerSubtitle: 'Query, edit and manage the access to the content.',
  assignContributorSubtitle: 'Query, read and add to the content.',
  emptyWorkspace: 'No Workspace available',
  emptyCollection: 'No Collection available',
  workspaces: 'Workspaces',
  collections: 'Collections',
  licenseLimitReached: 'License limit reached',

  // Chat
  Chat: 'Chat',
  chat: 'chat',
  createNewChat: 'New Chat',
  send: 'Send',
  queryPlaceholder:
    'Ask EmpowerGPT. Answers are generated only from documents in the selected language.',
  chatConfigurations: 'Chat configuration',
  parameterDialogTitle: 'Parameters',
  parameterDialogSubtitle:
    'Update chat parameters to search the documents efficiently',
  updateParameters: 'Update Parameters',
  cancel: 'Cancel',
  submit: 'Submit',
  save: 'Save',
  confirm: 'Confirm',
  parametersUpdateSuccess: 'Chat parameters updated successfully!',
  parametersUpdateError: 'Error updating chat parameters!',
  temperature: 'Temperature',
  tokens: 'Max length (tokens)',
  sequences: 'Stop sequences',
  probabilities: 'Top probabilities',
  frequency: 'Frequency penalty',
  presence: 'Presence penalty',
  chatRenameSuccess: 'Chat renamed successfully!',
  chatRenameFailed: 'Error renaming chat!',
  chatDeleteSuccess: 'Chat deleted successfully!',
  chatDeleteFailed: 'Error deleting chat!',
  generatingResponse: 'Generating response',
  errorGeneratingResponse: 'Could not generate response! Please try again!',
  messagesLoadingFailed: 'Error fetching messages. Please try again!',
  responseFailedMessage: 'There was an error generating a response.',
  responseStreamingFailedMessage:
    'There was an error in streaming response. Please refresh the page.',
  regenerateResponse: 'Regenerate Response',
  errorAddingReaction: 'Error adding reaction.',
  errorUpdatingChatContext: 'Error updating chat context.',
  chatDeleteConfirmation: 'Are you sure you want to delete the chat?',
  samplePrompts: 'Sample Prompts',
  capabilities: 'Capabilities',
  limitations: 'Limitations',
  chatGptSlogan:
    'For assistance in content creation, coding, research and access to world knowledge.',
  empowerGptSlogan: 'For answers in light of accessible knowledge base.',
  samplePrompt1:
    'What checks should I carry out before installing the Etaprime L pump?',
  samplePrompt2:
    'What is the allowable range of viscosity of Crude oil for L27/38S power plant?',
  samplePrompt4: 'Guidelines for creating pivot table in Microsoft Excel.',
  samplePrompt5: 'Remote team collaboration & communication tips.',
  samplePrompt7: 'Suggest supply chain optimization strategies.',
  samplePrompt8: 'Best practices for identifying corporate risks.',
  capabilities1:
    ' Get answers for your queries solely based on the uploaded documents.',
  capabilities2:
    'Remembers the context of the previous questions within a chat.',
  limitations1: ' May fail to compare attributes spread across the database.',
  limitations2: 'Knowledge confined to the scope of the uploaded documents.',
  includeChatHistory: "Click to reconnect with last query's context.",
  excludeChatHisory: 'Click to disconnect from chat context',
  stickToDocs: 'Stick to Docs: Limit search to documents open',
  resourceUnavailable: 'Resource Unavailable',
  referenceDocumentDeleted: 'Reference document deleted',
  disclaimerChatgpt:
    'ChatGPT may make mistake, please consider verifying important information. Powered by GPT-4.',
  askChatGptQuery: 'Ask your query.',
  copy: 'Copy',
  copied: 'Copied',
  errorCopyingResponse: 'Error copying response',
  stickDocMessage: 'Open referenced document to enable',
  contentFilteringErrorMsg: `The response was filtered due to the prompt triggering Azure's content management policy. Please modify your prompt and retry.`,
  generalErrorMsg:
    'The AI Model was not able to generate a response. Please try again.',

  // Chat feedback ...
  feedbackTitle: 'Provide additional feedback',
  feedback1: 'Response cites wrong document.',
  feedback2: 'Response out of scope of documents.',
  feedback3: 'Response is harmful/unsafe.',
  feedback4: 'Response is not helpful.',
  submitFeedback: 'Submit feedback.',
  sources: 'Sources',
  feedbackCommentPlaceholder:
    'What went wrong with the answer? How can it be imporved?',
  feedbackValidationError: 'Please select atleast 1 option.',

  // User/Roles/Permissions
  rolesTitle: 'Roles',
  role: 'Role',
  permissions: 'Permissions',
  users: 'Users',
  create: 'Create',
  update: 'Update',
  add: 'Add',
  new: 'New',
  edit: 'Edit',
  delete: 'Delete',
  tag: 'Tag',
  upload: 'Upload',
  createRole: 'Create Role',
  updateRole: 'Update Role',
  createUser: 'Create User',
  updateUser: 'Update User',
  searchRole: 'Search User Role',
  availableRole: 'Available User Role',
  assignedRole: 'Assigned User Role',
  confirmDeleteRole: 'Are you sure you want to delete this role?',
  confirmDeleteUser: 'Are you sure, you want to delete this user?',
  confirmDeleteMultipleUsers: 'Are you sure, you want to delete these users?',
  roleDeleteSuccess: 'Role deleted successfully!',
  deleteRow: 'Delete row',
  randomError: 'Some Error Occurred!',
  errorDeletingRole: 'Error Deleting Role.',
  noRecordFound: 'No Record Found!',
  roleUpdateSuccess: 'Role Updated Successfully!',
  roleUpdateError: 'Error updating role!',
  roleCreateSuccess: 'Role Created Successfully!',
  roleCreateError: 'Error creating role!',
  userInviteSuccess: 'An email invite has been sent to invited users.',
  userInviteError: 'Error while inviting users.',
  userUpdateSuccess: 'User updated successfully!',
  userUpdateFailed: 'Error while updating user!',
  userDeleteSuccess: 'User deleted successfully!',
  usersDeleteSuccess: 'Users deleted successfully!',
  userDeleteError: "Couldn't delete user, Please try again!",
  duplicateUserError: 'Email is already Taken',
  duplicateFolderError: 'File/Folder already exists!',
  duplicateCollectionError: 'Collection already exists!',
  duplicateWorkspaceError: 'Workspace already exists!',
  renameSuccess: 'Renamed successfully!',
  renameFailed: 'Error renaming!',
  rename: 'Rename',
  enterRoleName: 'Enter Role Name',
  fullName: 'Full Name',
  enterFullName: 'Enter Full name',
  enterEmailAddress: 'Enter Email Address',
  enterRoleDescription: 'Enter Role Description',
  roleName: 'Role Name',
  invalidRoleName: 'Invalid Role Name',
  userName: 'Username',
  enterUserName: 'Enter User name',
  moveSelectedRight: 'move selected right',
  moveSelectedLeft: 'move selected left',
  applyTags: 'Apply Tags',
  language: 'Language',
  dataLibrary: 'Data Library',
  documentsStatus: 'Uploaded Documents Status',
  statusFailedText: 'Status : Failed',
  statusUploadedText: 'Status : Uploaded',
  startDate: 'Start Date',
  endDate: 'End Date',
  selectStatus: 'Select Status',
  pages: 'pages',
  addARole: 'Add a Role',
  editRole: 'Edit Role',
  tagHelperText:
    "Press the 'Enter' key to create a new tag or to select one from the list",
  organizationAdminSubtitle:
    'Can create and assign workspaces, invite admins/users, add custom roles.',
  workspaceAdminSubtitle:
    'Can manage complete workspace(s), user groups, and add regular users.',
  regularUserSubtitle:
    'Can chat accessible documents, upload and share content with other users.',

  // workspace
  workspaceName: 'Workspace Name',
  collectionName: 'Collection Name',
  workspaceColor: 'Workspace Color',
  workspaceController: 'Workspace Controllers',
  createWorkspace: 'Create Workspace',
  updateWorkspace: 'Update Workspace',
  documentUpdateConfirmation: 'Rename workspace and update background color',
  backgroundColor: 'Background Color',
  updatesOn: 'Updates',
  updatedBy: 'Updated By',
  workspaceUpdateSuccess: 'Workspace updated successfully!',
  workspaceUpdateFailed: 'Error updating workspace!',
  sortBy: 'Sort By',
  lastUpdated: 'Last Updated',
  ago: 'ago',
  day: 'day',
  days: 'days',
  hour: 'hour',
  hours: 'hours',
  min: 'min',
  mins: 'mins',
  sec: 'sec',
  secs: 'secs',
  workSpaceDocumentsDropError:
    'Add a collection to upload the documents inside.',
  createAWorkspace: 'Create a Workspace',
  newWorkspaceDescription:
    'Create a separate knowledge base of each department',
  publicDisclaimer: 'Content in Public is shared with all users.',
  privateDisclaimer: 'Content in My workspace is only accessible to you.',

  // create collection
  createACollection: 'Create a Collection',
  //no_data_messages
  noDataNameSpaceMessage: 'Create a workspace to start uploading data within.',
  noDataWorkspaceMessage:
    'Upload a folder or create a collection to upload data within.',
  noDataCollectionMessage: 'Upload files/folders',
  noDataNoPermissionMessage: 'Request administrator for access to documents.',
  noData: 'No Data',
  workSpaceInfoMessage:
    'New workspaces can only be created by organization administrators.',
  moveDialogInfo:
    'Moved content will maintain its original source permissions.',
  // File statuses
  F_S_UP: 'In progress',
  F_S_RE: 'Retrying',
  F_S_PR: 'Processed',
  F_S_US: 'Unsupported',
  F_S_LLR: 'License Limit Reached',

  // User Groups
  userGroupDeletedSuccessfully: 'User Group Deleted Successfully.',
  userGroupsDeletedSuccessfully: 'User Groups Deleted Successfully.',
  userGroupCreatedSuccessfully: 'User Group Created Successfully.',
  failedToCreateUserGroup: 'Failed To Create User Group.',
  failedToDeleteUserGroup: 'Failed To Delete User Group.',
  failedToDeleteUserGroups: 'Failed To Delete User Groups.',
  userGroupUpdatedSuccessfully: 'User Group Updated Successfully.',
  failedToUpdateUserGroup: 'Failed To Update User Group.',
  addUserGroup: 'Add a User Group',
  editUserGroup: 'Edit User Group',
  searchUsers: 'Search Users',
  createdBy: 'Created By',
  userGroupDeleteConfirmationMsg: 'Are you sure to delete user group(s)?',

  //validations
  maxLength: '{{name}} cannot be longer than {{length}} characters',
  required: '{{name}} is required',

  // Error Messages
  namespaceError: 'Namespace error occurred.',
  resourceNotFoundError: 'Resource not found.',
  noPermissionsAgainstResourceError: 'No permissions against the resource.',
  appRoleTemplateNotFoundError: 'App role template not found.',
  insufficientPrivileges: 'Permission to perform the action not found.',
  uploadTypeError: 'Invalid upload type provided.',
  transactionIdError: 'Invalid Transaction ID provided.',
  uploadToRootError: 'Error uploading to root.',
  uploadToFoundError:
    'You are not allowed to upload files and folders in FOUND folder.',
  uploadToFileError: 'Files can only be uploaded in valid folders.',
  fileUploadToWorkspaceError: 'Files cannot be uploaded on workspace level.',
  noFilesAttachedError: 'Select valid files to start uploading them.',
  fileStorageWriteError: 'File storage write error.',
  fileStorageReadError: 'File storage read error.',
  requestedResourceNotFile: 'Requested resource is not a file.',
  fileStorageDeleteError: 'File storage delete error.',
  publicFolderDeleteError: 'Public folder cannot be deleted.',
  internalServerError: 'Something went wrong.',
  invalidArgsError: 'Invalid arguments provided.',
  folderNotAllowedHere: 'You are not allowed to create folder here.',
  rolesInsufficientViewPrivileges: "You don't have right to view roles.",
  userGroupsInsufficientPrivileges: "You don't have right to view user groups.",
  waCreateUserRestriction: 'Workspace admin can only create regular users.',
  readOnlyResourceUpdate: 'You are not allowed to update readonly resource.',
  readOnlyResourceDelete: 'You are not allowed to delete readonly resource.',
  rolesUniqueVoilation: 'Role with the name already exists.',
  addPermissionsAgainstResourceError:
    'Failed to add permissions against resource.',
  permissionsUpdatingErrorForRole:
    'Failed to update permissions for the resource.',
  insufficientCreatePrivileges: "You don't have rights to create resource.",
  insufficientUpdatePrivileges: "You don't have rights to update resource.",
  insufficientDeletePrivileges: "You don't have rights to delete resource.",
  insufficientDeleteOneOrMorePrivileges:
    "You don't have rights to delete some resources.",
  userGroupUniqueVoilation: 'User group with the same name already exists.',
  usersUniqueVoilation: 'User already exists!',
  selfAssignmentOnResourceRestriction:
    'You cannot assign yourself to resource.',
  ruAssignmentAsControllerRestriction:
    'Regular users cannot be assigned as controller.',
  oaRemovalFromResourceDenied:
    'Organization Administrator cannot be removed from resource.',
  insufficientAssignmentPrivileges:
    "You don't have enough rights to perform the operation.",
  peerAccountAssignmentRestriction:
    'You are not allowed to remove same or higher level accounts.',
  messageOnEmptyFolderOrUnavailableFile:
    'Chat cannot be initiated on empty or unavailable resources.',
  noSharedFilesExist:
    'Please upload documents to the data library or request admin for access to start using the questioning.',
  itemDetailsNotFoundError: 'Details for this resource could not be found.',
  chatCreditLimitExceededOA:
    'Monthly limit for AI credits reached. For immediate assistance, contact customer support at “support@empowergpt.ai”',
  chatCreditLimitExceeded:
    'Monthly limit for AI credits reached. Please contact your administrator.',
  fileCreditLimitExceededOA:
    'Document processing halted, monthly limit for AI credits reached. For immediate assistance, contact customer support at “support@empowergpt.ai”. ',
  fileCreditLimitExceeded:
    'Document processing halted, monthly limit for AI credits reached. Please contact your administrator.',
  fileWordLimitExceededOA:
    'Document processing halted, word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space. For further assistance, contact customer support at “support@empowergpt.ai”.',
  fileWordLimitExceeded:
    'Document processing halted, word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space.',
  creditLimitExceeded90Percent: 'Over 90% of AI credits have been consumed.',
  wordLimitExceeded90Percent:
    'Over 90% of allocated word limit against processed documents stored has been reached',
  wordLimitReached100Percent:
    'Allocated word limit for processed documents stored has been reached. Please delete documents that are no longer required to free up space. For further assistance contact customer support at “support@empowergpt.ai”',
  // resource provider errors
  operationNotPermitted: 'Permission to perform the action not found.',
  // Usage
  processedDocuments: 'Processed Documents',
  overallUsage: 'Overall Usage',
  aiUsage: 'AI Usage',
  monthlyUsage: 'Monthly Usage',
  dailyUsage: 'Daily Usage',
  processedDocumentsInfo:
    'Estimated number of words against processed documents stored in data library.',
  tokenUsageInfo:
    '{{consumed}} {{unit}} of processed documents stored in the data library out of the {{total}} {{unit}} limit',
  aiUsageInfo: 'AI usage for chat, and document processing',
  creditUsageInfo:
    '{{consumed}} {{unit}} used out of the {{total}} {{unit}} limit',
  credits: 'Credits',
  consumed: 'Consumed',
  date: 'Date',
  filesPermission: 'Files are not allowed here',
  noCreditsUsedThisMonth: 'No credits used this month',
  uniqueFolderNameError: 'Folder already exists.',
  noResourcesShared:
    'Authorized documents are allowed only. Please upload a document first.',
  // users invitations
  inviteUsers: 'Invite Users',
  emailAddress: 'Email Address',
  userGroup: 'User Group',
  active: 'Active',
  invited: 'Invited',
  invitationExpired: 'Invitation Expired',
  inactive: 'Inactive',
  resendInvite: 'Resend Invite',
  deActivate: 'Deactivate',
  activate: 'Activate',
  inviteSentSuccessfully: 'Invite sent successfully!',
  inviteSentFailed: 'Error sending invite!',
  userActivated: 'User activated successfully!',
  userDeactivated: 'User deactivated successfully!',
  userActivationFailed: 'Error activating user!',
  userDeactivationFailed: 'Error deactivating user!',
  adUserNotFoundError: 'User not found in AD.',
  invalidEmail: 'Please enter a valid email address',

  // SharePoint
  sharePointAuthError:
    'You might not be able to interact with SharePoint due to connectivity issue!',
  sharepoint: 'Import from SharePoint',
  sharepointModalTitle: 'Select a Folder / Library',
  sharepointModalSubtitle:
    'Navigate to the desired site, and select the target folder / library for importing',
  availableSites: 'Available Sites',
  selectSiteMsg: 'Select a site to view available site content',
  siteContent: 'Site Content',
  showSiteContent: 'Show Site Content',
  select: 'Select',
  collectionSharepointPlaceholder: 'Create a SharePoint synced collection',
  collectionSharepointDescription:
    'Integrate a SharePoint folder into your workspace as a synced collection',
  resourceImportedSuccessfully: 'Resource imported successfully.',
  rootSite: 'Root Sites',
  sharePointSuccessModalTitle: 'Folder / Library Linked Successfully',
  sharePointSuccessModalLine1:
    'All set! Selected folder / library along with its contents shall be accessible to you as a Collection once the data sync completes.',
  sharePointSuccessModalLine2:
    'The Collection’s visibility is solely based on its permissions at SharePoint.',
  noSubSiteAvailable: 'No Sub site available.',
  noFolderFileAvailable: 'No Folder / File available.',
  openInSharePoint: 'Open in SharePoint',
  sharePointResourceDisclaimer:
    'Access permissions are managed on SharePoint and synced in EmpowerGPT.',

  // SSO configuration
  redirectUri: 'Redirect URI',
  signInWithMicrosoft: 'Sign in with Microsoft (SSO)',
  allowInvitingExternalUsers: 'Allow inviting external users',
  cloudDriveIntegration: 'Cloud Drive Integration',
  syncFrequency: 'Sync Frequency (mins)',
  contentImportFromSharepoint: 'Content import from SharePoint',

  // steps to configure SSO
  letsStart: "Let's start",
  next: 'Next',
  done: 'Done',
  welcomeScreenHeading1: 'Steps for SSO',
  welcomeScreenHeading2: 'Configuration',
  registrationStep: 'Register “EmpowerGPT” in Microsoft Entra ID',
  azureDetailsStep:
    'Enter details against the registered application on Microsoft Entra ID',
  configurePermissions:
    'Give required permissions against used APIs to the registered app in Microsoft Entra ID',

  // SSO configuration RegistrationStep
  registrationPoint1: 'In another tab, sign in to',
  azurePortal: 'Azure Portal',
  roleAzurePortal: 'as an admin.',
  registrationPoint2:
    'Navigate to "Microsoft Entra ID" (Azure Active Directory) > "App registrations" as an admin.',
  registrationPoint3: 'Click on "+ New registration".',
  registrationPoint4:
    'Enter “EmpowerGPT” as the name of the application and select the required supported account types. (Recommended: “Accounts in this organizational directory only”).',
  registrationPoint5:
    'Under the “Redirect URI” section on the application registration form, select “Web” as the type and paste the following Redirect URI in the corresponding field.',
  confirmationStep1:
    'The above steps have been performed on the registered application in Microsoft Entra ID.',
  // Registration Step form validation
  redirectUriRequired: 'Redirect URI is required',

  // SSO configuration Azure Details Step
  azureDetailsPoint1:
    "Once the application is registered, you will be redirected to the registered application's “Overview” page.",
  azureDetailsPoint2:
    'Copy the “Application (client) ID” and “Directory (tenant) ID” from the “Overview” page and enter each it in the respective fields below.',
  azureDetailsPoint3:
    'Navigate to “Certificates and secrets” under the “Manage” section on the registered application in Microsoft Entra ID.',
  azureDetailsPoint4:
    ' Select the “Client secrets” tab and click “New client secret” button to create a new client secret. Enter “EmpowerGPT client secret” in description, set the expiry to 24 months (recommended) and click “add”.',
  azureDetailsPoint5:
    'Copy the secret "Value" and paste it in the field below. Client secret values cannot be viewed. except for immediately after creation. Be sure to save the secure value when created before leaving the page.',
  // Azure Details Step form validation
  clientIdRequired: 'Client Id is required',
  labelClientId: 'Application (client) Id: ',
  tenantIdRequired: 'Tenant Id is required',
  labelTenantId: 'Directory (tenant) Id:',
  secretValueRequired: 'Secret Value is required',
  labelSecret: 'Client Secret Value:',
  enter: 'Enter...',
  // SSO configuration Permissions Step
  permissionsStep1:
    'Navigate to “API permissions” under the “Manage” section on the registered application in Microsoft Entra ID.',
  permissionsStep2:
    'Click "+ Add a permission" button and select "Microsoft Graph" under Microsoft APIs.',
  permissionsStep3:
    'Select "Application permissions" to give the following permissions and confirm by clicking "add permission" button below.',

  permissionsStep4: 'User.Read.All under User.',
  permissionsStep5: 'Group.Read.All under Group.',
  permissionsStep6: 'GroupMember.Read.All under GroupMember.',
  permissionsStep7:
    'Repeat step 2 and select "Delegated permissions" to give the "profile" permission. Confirm by clicking "add permission" button below.',
  permissionsStep8:
    'The above steps have been performed on the registered application in Microsoft Entra ID.',

  successDialogTitle: 'SSO Configured Successfully',
  successDialogDescription:
    'SS0 has been successfully configured against users within the linked directory.',
  clickHere: 'Click here',
  toLogout: ' to logout and test the configuration.',
  // Resource Provider config
  resourceProviderWelcomeTitle:
    'Steps for Import from SharePoint Configuration',
  resourceProviderTitle1:
    'Upload generated certificate at registered application “EmpowerGPT” on Microsoft Entra ID',
  resourceProviderStep1Instruction1:
    "Paste the base URL for your organization's SharePoint below. (The base URL for SharePoint Online is provisioned based off your input when setting up your Microsoft 365 subscription. For example, if your organization name is Contoso, the root site for SharePoint Online will be https://contoso.sharepoint.com.).",
  resourceProviderStep1Instruction2:
    'Click the button below to download the required certificate.',
  resourceProviderStep1Instruction3:
    'In another tab, sign in to Azure Portal as an admin.',
  resourceProviderStep1Instruction4:
    'Navigate to “Microsoft Entra ID” (Azure Active Directory) > “App registrations” > ”All applications” > ”EmpowerGPT”.',
  resourceProviderStep1Instruction5:
    'Select the application and navigate to “Certificates and secrets” under the Manage section.',
  resourceProviderStep1Instruction6:
    'Select the “Certificates” tab and click on upload certificate. Upload the above generated certificate, downloaded on your computer.',
  resourceProviderStep1Instruction7:
    'Enter suitable description and click the "Add" button to add the certificate.',
  resourceProviderTitle2:
    'Give required permissions against used APIs to the registered app in Microsoft Entra ID',
  resourceProviderStep2Instruction1:
    'Navigate to “API permissions” under the “Manage” section on the registered application in Microsoft Entra ID.',
  resourceProviderStep2Instruction2:
    'Click “+ Add a permission” button and select “Microsoft Graph” under Microsoft APIs.',
  resourceProviderStep2Instruction3:
    'Select “Application permissions” to give the following permissions and confirm by clicking “add permission” button below.',
  resourceProviderStep2Instruction5: 'Sites.Read.All.',
  resourceProviderStep2Instruction6: 'Files.Read.All.',
  resourceProviderStep2Instruction7:
    'Repeat step 2 and select "Delegated permissions" to give the following permissions. Confirm by clicking "add permission" button below.',
  resourceProviderStep2Instruction8:
    'Click "+ Add a permission" button and select "SharePoint" under Microsoft APIs to give the following "Application permission". Click "add permission" to confirm.',
  sharepointConfigurationSuccessTitle: 'Importing from SharePoint Configured',
  sharepointConfigurationSuccessDescription1:
    'Content can now be imported from SharePoint sites in EmpowerGPT as collections.',
  sharepointConfigurationSuccessDescription2:
    "Imported content's visibility will be based on its permissions at SharePoint.",
  Navigate: 'Navigate',
  downloadCertificate: 'Download Certificate',
  invalidSharePointUrl: 'Invalid SharePoint URL provided.',
  identityProviderEnabledSuccessfully: 'Microsoft (SSO) enabled successfully.',
  resourceProviderEnabledSuccessfully: 'SharePoint enabled successfully.',
  identityProviderDisabledSuccessfully:
    'Microsoft (SSO) disabled successfully.',
  resourceProviderDisabledSuccessfully: 'SharePoint disabled successfully.',
  identityProviderUpdateFailed: 'Error updating Microsoft (SSO).',
  resourceProviderUpdateFailed: 'Error updating SharePoint.',
  identityProviderCreationFailed: 'Error enabling Microsoft (SSO).',
  resourceProviderCreationFailed: 'Error enabling SharePoint.',
  disabledToolTipMicrosoftSSO: 'Configure Microsoft SSO to enable.',
  requiredCheckbox: 'This field is required.',
  adminConsent:
    'Make sure admin consent is provided for permissions that require it, indicated by a “Yes” in the “Admin consent required” column of the “Configured permissions” table.',

  //Internal Analytics
  analytics: 'Analytics',
  questions: 'Questions',
  dislikes: 'Dislikes',

  // User Favourites
  favourites: 'Favourites',
  markFavourite: 'Mark as favourite',
  unmarkFavourite: 'Unmark as favourite',
  markedFavourite: 'Marked as favourite',
  unmarkedFavourite: 'Unmarked as favourite',
  markFavouriteError: 'Error marking as favourite',
  unmarkFavouriteError: 'Error unmarking as favourite',
  favouriteLimitReached: 'User favourites limit reached',
  // user onboard
  welcomeText: 'Welcome to',
  welcomeText1:
    'EmpowerGPT is an AI Platform made for Enterprises. Chat securely with state-of-the-art LLMs incorporating your enterprise and world knowledge.',
  welcomeText2: `Expand your enterprise’s knowledge base by uploading, or importing data from integrated applications.`,
  // User Onboarding
  //Chat
  gotIt: 'Got it',
  onNext: 'Click Next to continue.',
  chatStep1Title: 'Chat: Select the required knowledge domain.',
  chatStep1Description: `Choose how you want EmpowerGPT to respond. Stick to enterprise knowledge for answers in light of accessible enterprise documents. Or switch to world knowledge for assistance in content creation, coding, research and access to world knowledge.`,
  chatStep2Title: 'Chat: Type in your query.',
  chatStep2Description: `Use the input field below to enter your queries, and hit the send button on the right. Context against prior queries and responses is limited within a chat`,
  chatStep3Title: 'Chat: Review the facts.',
  chatStep3Description: `Chat responses in enterprise knowledge have references cited at the end. It is recommended to validate the authenticity of the response by viewing the references.`,
  chatQuery: 'What are different account types in EmpowerGPT?',
  // Data Library
  documentStep1Title: 'Upload: Navigate to the Data Library.',
  documentStep1Description:
    'Data Library contains workspaces that have been shared with you. Workspaces categorize documents within an organization.',
  documentStep2Title: 'Upload: Select the required Workspace.',
  documentStep2Description: `Actions available on a workspace are based on the role you have been assigned on them. “My Workspace” is a personal workspace, you can add documents and chat with.`,
  documentStep3Title: 'Upload: Create a collection.',
  documentStep3Description: `Collections further classify documents within each workspace. Create your collection within the My workspace to upload documents inside.`,
  documentStep4Title: 'Upload: Select documents to upload',
  documentStep4Description: `EmpowerGPT supports a wide variety of document formats for users to add to the knowledge base, including PDFs (scanned documents as well), Word documents, Powerpoint slides, and Excel files.`,
  documentStep5Title: 'Upload: Understanding document status',
  documentStep5Description: `Documents once uploaded are queued for AI processing. Once marked as “Processed”,  users who have access to these documents can chat with them.`,

  // Invite User
  inviteUserStep1Title: 'Invite: Open up the menu',
  inviteUserStep1Description:
    'Menu buttons contains links to pages required to setup, manage the application, and your account.',
  inviteUserStep2Title: 'Invite: Navigate to the users page',
  inviteUserStep2Description:
    'Users page contains list of all people currently on the empowerGPT platform. Admins can invite users from this page.',
  inviteUserStep3Title: `Invite: Type in the invitee’s email`,
  inviteUserStep3Description:
    'Type the emails for the invitees, select their account type, and user groups if any, before sending them the invite.'
};
