import { FC, ReactNode, useContext } from 'react';
import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Main, drawerWidth, miniDrawerWidth } from './styles';
import UserOnboard from 'src/components/UserOnboard';
import { RootState, useSelector } from 'src/redux/store';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const theme = useTheme();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { isOnboarded, isLangInitialized } = useSelector(
    (state: RootState) => state.data
  );
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,

          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={sidebarToggle}
      >
        <Sidebar />
      </Drawer>
      <Drawer
        sx={{
          width: `${miniDrawerWidth}px`,
          flexShrink: 0,
          position: 'absolute',
          left: 0,
          '& .MuiDrawer-paper': {
            width: `${miniDrawerWidth}px`,
            boxSizing: 'border-box',
            backgroundColor: theme.sidebar.background
          }
        }}
        variant="persistent"
        anchor="left"
        open={!sidebarToggle}
      >
        <IconButton
          aria-label="open drawer"
          onClick={closeSidebar}
          color="secondary"
          sx={{
            my: theme.spacing(2),
            mx: 'auto',
            ...(sidebarToggle && { display: 'none' }),
            border: `1px solid ${theme.colors.secondary.main}`,
            padding: theme.spacing(1.4)
          }}
        >
          <SidebarIcon height={'16px'} width={'16px'} />
        </IconButton>
      </Drawer>
      <Main open={!sidebarToggle}>
        <Container
          maxWidth={false}
          disableGutters
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Outlet />
          {!isOnboarded && isLangInitialized && <UserOnboard />}
        </Container>
      </Main>
    </Box>
  );
};

export default AccentHeaderLayout;
