import { useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import { NavLink as RouterLink } from 'react-router-dom';
import { ChatData } from 'src/types/chat';
import { Box, IconButton, Stack, lighten, useTheme } from '@mui/material';
import { RootState, useSelector } from 'src/redux/store';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RenameChat from './RenameChat';
import DeleteChat from './DeleteChat';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';

type Props = {
  data: ChatData;
  onChatDeleted: (chatId: number) => void;
  onTitleEdited: (id: number, title: string) => void;
};

function ChatList({ data: item, onTitleEdited, onChatDeleted }: Props) {
  const theme = useTheme();
  const buttonRef = useRef<HTMLAnchorElement | null>(null);
  const [isEditingEnabled, setEditingEnabled] = useState<boolean>(false);
  const { selectedChat } = useSelector((state: RootState) => state.chat);

  const showMoreButton = useMemo(
    () => selectedChat === item.id,
    [selectedChat, item]
  );

  const toggleEditing = () => {
    setEditingEnabled((isEditing) => !isEditing);
  };

  return (
    <ListItem key={item.id} disablePadding>
      <Box
        width="100%"
        style={{
          position: 'relative'
        }}
        component={'div'}
        title={item.subject}
      >
        <Button
          ref={buttonRef}
          disableRipple
          fullWidth
          className={`${showMoreButton ? 'Mui-active' : ''}`}
          component={selectedChat !== item.id ? RouterLink : Button}
          startIcon={
            <ChatBubbleOutlineIcon
              sx={{
                color: 'white',
                padding: theme.spacing(0.2)
              }}
            />
          }
          to={`/chat/${item.id}`}
          sx={{
            height: '40px',
            borderRadius: theme.general.borderRadiusSm,
            ['.chat-action-buttons']: {
              display: 'none'
            },
            ['&:hover']: {
              pr: 0,
              background: `linear-gradient(to right, ${lighten(
                theme.sidebar.menuItemBg,
                0.3
              )} 70%, ${theme.sidebar.menuItemBg})`,
              ['.chat-action-buttons']: {
                display: 'flex'
              }
            }
          }}
        >
          {isEditingEnabled ? (
            <RenameChat
              toggleEditing={toggleEditing}
              title={item.subject}
              onTitleEdited={onTitleEdited}
            />
          ) : (
            <>
              <CustomListItemText primaryText={item.subject} />
              <Box
                className="chat-action-buttons"
                flexDirection={'row-reverse'}
                sx={{
                  width: '70px',
                  height: '100%'
                }}
              >
                <DeleteChat onDelete={onChatDeleted} />
                <IconButton onClick={toggleEditing} size="small">
                  <EditOutlinedIcon htmlColor="white" fontSize="small" />
                </IconButton>
              </Box>
            </>
          )}
        </Button>
      </Box>
    </ListItem>
  );
}

export default ChatList;
