import { styled } from '@mui/material';

export const drawerWidth = 300;
export const miniDrawerWidth = 60;
const marginLeft = drawerWidth - miniDrawerWidth;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open'
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: '0',
  display: 'flex',
  width: '100%',
  maxWidth: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    maxWidth: `calc(100% - ${miniDrawerWidth}px)`,
    marginLeft: `-${marginLeft}px`
  })
}));
