import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import router from 'src/router';
import { useDispatch, useSelector } from './redux/store';
import { authSignIn } from './redux/slices/auth';
import { getKccToken } from './keycloak';
import AppInit from './components/AppInit';
import useGlobalData from './hooks/useGlobalData';
import { isEmpty } from 'lodash';
import { setAlert } from './redux/slices/snackbar';
import { useTranslation } from 'react-i18next';
import { checkCreditsLimit } from './utils/utils';
import SnackbarAlert from './components/NotiStack';
import { ACCOUNT_TYPE } from './types/enum';
import { updateUserOnboarding } from './redux/slices/data';
import useMutation from './hooks/useMutation';
import { ErrorContext } from './utils/errorMappings';
import { useUpdateUserPreferencesMutation } from './services/api';

function App() {
  const [updateOnboarding] = useMutation({
    api: useUpdateUserPreferencesMutation,
    errorContext: ErrorContext.GENERAL
  });
  const { isLangInitialized, userAppPermissions, areModelsInitialised } =
    useSelector((state) => state.data);
  const content = useRoutes(router(userAppPermissions));
  const dispatch = useDispatch();
  const { initialize } = useGlobalData();
  const { isAuthorized, user, licenseInfo } = useSelector(
    (state) => state.auth
  );
  const { isOpen } = useSelector((state) => state.snackbar);
  const { t } = useTranslation();
  const kcc_token: any = getKccToken();

  useEffect(() => {
    if (kcc_token === 'true') {
      dispatch(authSignIn());
    }
  }, []);
  useEffect(() => {
    if (isAuthorized && user) {
      initialize();
    }
  }, [user]);

  const markUserOnboardingComplete = async () => {
    await updateOnboarding({
      params: { params: { name: 'onboarded', v: 'true' } }
    });
    dispatch(updateUserOnboarding(true));
  };
  useEffect(() => {
    if (
      isAuthorized &&
      user &&
      isLangInitialized &&
      user.accountType === ACCOUNT_TYPE.ORGANIZATION_ADMINISTRATOR
    ) {
      const messages = checkCreditsLimit(licenseInfo);
      for (const message in messages) {
        //TODO: figure out another way in place of timeout
        setTimeout(() => {
          const { msg, type } = messages[message];
          dispatch(setAlert({ type, msg: t(msg) }));
        }, 0);
      }
    }
  }, [user, isLangInitialized]);

  useEffect(() => {
    const checkIfPageRefreshed = () => {
      const entries = performance.getEntriesByType(
        'navigation'
      ) as PerformanceNavigationTiming[];
      if (entries.length > 0 && entries[0].type === 'reload') {
        markUserOnboardingComplete();
      }
    };
    checkIfPageRefreshed();
  }, []);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {isAuthorized &&
        user &&
        isLangInitialized &&
        areModelsInitialised &&
        !isEmpty(userAppPermissions) ? (
          content
        ) : (
          <AppInit />
        )}
        {isOpen && <SnackbarAlert />}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
