import { ONBOARDING_STEPS_TARGET } from '../../constants';

export const handleDataLibrary = () => {};

export const handlePrivateWorkspaceClick = () => {
  const privateWorkspace = document.getElementById(
    ONBOARDING_STEPS_TARGET.DOCUMENT_PRIVATE_WORKSPACE
  ) as HTMLButtonElement;
  if (privateWorkspace) {
    privateWorkspace.click();
  }
};

export const handleAddButtonClick = () => {
  const addButton = document.getElementById('add-button') as HTMLButtonElement;

  if (addButton) {
    addButton.click();
  }
};

export const updateSpotLightHeight = () => {
  const spotLights = document.getElementsByClassName(
    ONBOARDING_STEPS_TARGET.REACT_JOYRIDE_SPOTLIGHT
  ) as HTMLCollectionOf<HTMLDivElement>;

  if (spotLights.length) {
    spotLights[0].style.height = '122px';
  }
};

export const handleNewButtonClick = () => {
  const newButton = document.getElementById(
    ONBOARDING_STEPS_TARGET.DOCUMENT_ADD_MENU_NEW_BUTTON
  ) as HTMLButtonElement;
  if (newButton) {
    newButton.click();
  }
};

export const handleUploadButtonClick = () => {
  const uploadButton = document.getElementById(
    ONBOARDING_STEPS_TARGET.DOCUMENT_UPLOAD_LINK
  ) as HTMLButtonElement;
  if (uploadButton) {
    uploadButton.click();
  }
};
