import {
  UPLOAD_CONSTRAINT_RESOURCES,
  initialNewRow,
  isRowValid,
  validate
} from './utils';
import { AppDispatch } from 'src/redux/store';
import { TFunction } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { setAlert } from 'src/redux/slices/snackbar';
import { getInitials } from 'src/utils/utils';
import { StringKeys } from 'src/types/base';
import {
  ALERT,
  ACTION_TYPES,
  DEFAULT_CONTENT_ROLES,
  RESOURCE_TYPE
} from 'src/types/enum';
import { Theme } from '@mui/material/styles';
import { AccessRightsUpdateHandlerType } from 'src/hooks/useUpdateAccessRights';
import {
  closeDocumentViewer,
  createChatSnapshot,
  defaultChatContext,
  setSelectedChat
} from 'src/redux/slices/chat';

export const addResource = async (
  currentResourceType: RESOURCE_TYPE,
  added: StringKeys,
  rows: StringKeys[],
  currentActiveResource: string | null,
  newRowDataRef: React.MutableRefObject<StringKeys>,
  setAddNewRow: React.Dispatch<React.SetStateAction<boolean>>,
  setEditingRowIds: React.Dispatch<React.SetStateAction<number[]>> | null,
  setValidationStatus: React.Dispatch<React.SetStateAction<StringKeys>> | null,
  dispatch: AppDispatch,
  createResource: any,
  theme: Theme,
  t: TFunction<'translation', undefined>,
  handleUpdateAccessRights?: AccessRightsUpdateHandlerType,
  addedUsers?: StringKeys[],
  removedUsers?: StringKeys[],
  type_id?: string
) => {
  const isExisting = rows.find((row: any) => row.name === added.name);
  if (isExisting) {
    newRowDataRef.current = {
      ...initialNewRow
    };
    if (setEditingRowIds) setEditingRowIds([]);
    setAddNewRow(false);
    if (setValidationStatus) setValidationStatus({});
    dispatch(
      setAlert({
        msg: UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType)
          ? t(T.duplicateCollectionError)
          : currentResourceType === RESOURCE_TYPE.NAMESPACE
          ? t(T.duplicateWorkspaceError)
          : t(T.duplicateFolderError),
        type: ALERT.ERROR
      })
    );
  } else {
    let initials = getInitials(added?.name);

    const params: any = {
      parent_id: currentActiveResource || null,
      name: added?.name || '',
      initials: initials,
      initials_color: added?.initials_color || theme.colors.success.light
    };
    await createResource({
      params: {
        params
      },
      fallbackMsg: T.folderCreateError,
      successCallback: (res: any) => {
        if (res) {
          dispatch(
            setAlert({
              msg: UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType)
                ? t(T.collectionCreateSuccess)
                : currentResourceType === RESOURCE_TYPE.NAMESPACE
                ? t(T.workspaceCreateSuccess)
                : t(T.folderCreateSuccess),
              type: ALERT.SUCCESS
            })
          );
          if (
            handleUpdateAccessRights &&
            (addedUsers?.length || removedUsers?.length)
          ) {
            const id = res.data?.[0]?.id;
            handleUpdateAccessRights({
              id: id,
              role: DEFAULT_CONTENT_ROLES.CONTROLLER,
              addedUsers: addedUsers,
              removedUsers: removedUsers,
              assignmentType: '',
              type_id: type_id
            });
          }
        } else {
          dispatch(
            setAlert({
              msg: t(T.folderCreateError),
              type: ALERT.ERROR
            })
          );
        }
      }
    });
    setAddNewRow(false);
    newRowDataRef.current = {
      ...initialNewRow
    };
    if (setEditingRowIds) setEditingRowIds([]);
    if (setValidationStatus) setValidationStatus({});
  }
};

export const updateResource = async (
  changed: StringKeys,
  rows: StringKeys[],
  addNewRow: boolean,
  newRowDataRef: React.MutableRefObject<StringKeys>,
  setValidationStatus: React.Dispatch<React.SetStateAction<StringKeys>> | null,
  currentActiveResource: string | null,
  setRows: React.Dispatch<React.SetStateAction<StringKeys[]>>,
  dispatch: AppDispatch,
  t: TFunction<'translation', undefined>,
  updateResource: any = null,
  handleUpdateAccessRights?: AccessRightsUpdateHandlerType,
  addedUsers?: StringKeys[],
  removedUsers?: StringKeys[],
  type_id?: string
) => {
  const changedObject: any = Object.values(changed)[0];
  const changedRowId = Object.keys(changed)[0];
  let changedRow: any = rows.filter(
    (row: any) => parseInt(changedRowId) === row.id
  );
  if (
    !changedObject &&
    isRowValid({ ...changedRow[0], ...changedObject }) &&
    !addNewRow
  ) {
    return;
  }

  if (addNewRow && parseInt(changedRowId) === initialNewRow.id) {
    newRowDataRef.current = {
      ...initialNewRow,
      ...changedObject
    };
    if (setValidationStatus)
      setValidationStatus((prev) => {
        return {
          ...prev,
          [0]: {
            ...validate({
              ...initialNewRow,
              ...changedObject
            })
          }
        };
      });
    return;
  }

  if (isRowValid({ ...changedRow[0], ...changedObject }) && updateResource) {
    let changedRow: any = rows.filter(
      (row: any) => parseInt(changedRowId) === row.id
    );

    const initials = changedObject?.name
      ? getInitials(changedObject?.name)
      : getInitials(changedRow[0]?.name);

    let params: any = {
      op: currentActiveResource ? 'rename' : 'set-props',
      new_name: changedObject?.name || changedRow[0]?.name,
      id: changedRowId
    };

    if (!currentActiveResource) {
      params = {
        ...params,
        initials: initials,
        initials_color: changedObject?.initials_color
      };
    }

    await updateResource({
      params: {
        params
      },
      fallbackMsg: !currentActiveResource
        ? T.workspaceUpdateFailed
        : T.renameFailed,
      successCallback: (res: any) => {
        if (res) {
          setRows((prev) => {
            return prev.map((row: any) => {
              if (row.id === parseInt(changedRowId)) {
                return {
                  ...row,
                  ...changedObject
                };
              }
              return row;
            });
          });
          dispatch(
            setAlert({
              msg: !currentActiveResource
                ? t(T.workspaceUpdateSuccess)
                : t(T.renameSuccess),
              type: ALERT.SUCCESS
            })
          );
          if (
            handleUpdateAccessRights &&
            (addedUsers?.length || removedUsers?.length)
          ) {
            const id = res.data?.[0]?.id;
            handleUpdateAccessRights({
              id: id,
              role: DEFAULT_CONTENT_ROLES.CONTROLLER,
              addedUsers: addedUsers,
              removedUsers: removedUsers,
              assignmentType: '',
              type_id: type_id
            });
          }
        }
      }
    });
    if (setValidationStatus)
      setValidationStatus((prev) => {
        const newState = { ...prev };
        delete newState[changedRowId];
        return newState;
      });
  } else {
    if (setValidationStatus)
      setValidationStatus((prev) => {
        return {
          ...prev,
          [changedRowId]: {
            ...validate({ ...changedRow[0], ...changedObject })
          }
        };
      });
    return;
  }
};

export const handleSubmission = (
  actionType: ACTION_TYPES,
  addNewRow: boolean,
  rowData: StringKeys,
  newRowDataRef: React.MutableRefObject<StringKeys>,
  setAddNewRow: React.Dispatch<React.SetStateAction<boolean>>,
  setValidationStatus: React.Dispatch<React.SetStateAction<any>> | null,
  onExecute: (params?: any) => void,
  onRowAdded: (newRowData: StringKeys) => void
) => {
  if (actionType === ACTION_TYPES.CANCEL) {
    if (addNewRow && rowData.id === 0) {
      setAddNewRow(false);
      newRowDataRef.current = { ...initialNewRow };
    }
    if (setValidationStatus)
      setValidationStatus((prev) => {
        const newState = { ...prev };
        delete newState[rowData.id];
        return newState;
      });
    onExecute();
    return;
  }
  if (actionType === ACTION_TYPES.COMMIT) {
    onExecute();
    if (rowData.id === 0 && addNewRow && isRowValid(newRowDataRef.current)) {
      onRowAdded(newRowDataRef.current);
    }
    return;
  }
  return;
};

export const startChat = ({
  documentContext,
  dispatch,
  navigate,
  selectedChat,
  routeState
}: {
  documentContext: any;
  dispatch;
  navigate;
  selectedChat: number;
  routeState?: any;
}) => {
  if (routeState?.id !== documentContext?.id || selectedChat !== 0) {
    dispatch(closeDocumentViewer(selectedChat));
    dispatch(setSelectedChat(undefined));
    dispatch(
      createChatSnapshot({
        chatId: 0,
        chatContext: {
          ...defaultChatContext,
          documentContext
        }
      })
    );
    setTimeout(() => {
      dispatch(setSelectedChat(0));
    }, 100);
    navigate(`/chat`, { state: { documentContext } });
  }
};
