import { useState, useEffect, useRef } from 'react';
import { Box, List } from '@mui/material';
import { useLazyGetChatsQuery } from 'src/services/api';
import { CHAT_LIST_PAGE_SIZE, chatListApiParams } from 'src/content/Chat/utils';
import ChatList from './Chat/ListItem';
import _ from 'lodash';
import { ChatData } from 'src/types/chat';
import CreateChat from './Chat/CreateChat';
import { SubMenuWrapper } from '../styles';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { setChats } from 'src/redux/slices/chat';
import { RootState, useDispatch } from 'src/redux/store';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';
import { useSelector } from 'react-redux';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import { ErrorContext } from 'src/utils/errorMappings';

export const useStyles = makeStyles<typeof PureLightTheme>((theme) => ({
  container: {
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: theme.colors.primary.main
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      borderRadius: '10px'
    }
  }
}));

function SidebarTopSection() {
  const dispatch = useDispatch();
  const [chatList, setChatList] = useState([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const classes = useStyles();
  const [currentPageLength, setCurrentPageLength] = useState<number>(0);
  const [fetchChat, { data }] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetChatsQuery,
    errorContext: ErrorContext.CHAT
  });
  const navigate = useNavigate();
  const { chats } = useSelector((state: RootState) => state.chat);

  useEffect(() => {
    if (data?.data) {
      if (page === 0) {
        dispatch(setChats(data.data));
      } else {
        dispatch(setChats([...chats, ...data.data]));
      }
    }
  }, [data]);

  useEffect(() => {
    if (chats.length) {
      setChatList(chats);
      setCurrentPageLength(chats.length || 0);
    }
  }, [chats]);

  useEffect(() => {
    fetchChatList();
  }, [page]);

  const fetchChatList = () => {
    fetchChat({ params: chatListApiParams(page) });
  };

  const onTitleEdited = (editedId: number, updatedTitle: string) => {
    setChatList((updatedData) =>
      updatedData.map((item: ChatData) =>
        item.id === editedId ? { ...item, subject: updatedTitle } : item
      )
    );
  };

  const onChatDeleted = (deletedId: number) => {
    let updatedData = [...chatList];
    _.remove(updatedData, (item: ChatData) => item.id === deletedId);
    setChatList(updatedData);
    navigate('/chat', {
      replace: true
    });
    setPage(0);
  };

  const handleScroll = (_: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (
      Math.floor(scrollTop) === Math.floor(scrollHeight - clientHeight) &&
      currentPageLength === CHAT_LIST_PAGE_SIZE
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <CreateChat />
      <Box position={'relative'} flex={1}>
        <Box
          position={'absolute'}
          height={'100%'}
          width={'100%'}
          onScroll={handleScroll}
          ref={scrollRef}
          className={classes.container}
          sx={{
            overflowY: 'scroll'
          }}
        >
          <SubMenuWrapper
            sx={{
              position: 'relative'
            }}
          >
            <List component="div" disablePadding>
              {chatList.map((item) => (
                <ChatList
                  data={item}
                  key={item.id}
                  onChatDeleted={onChatDeleted}
                  onTitleEdited={onTitleEdited}
                />
              ))}
              <Box height="20px" /> {/* To make the last item accessible */}
            </List>
          </SubMenuWrapper>
        </Box>
        <Box
          position={'absolute'}
          sx={{
            bottom: 0,
            zIndex: 20,
            width: '100%',
            height: '40px',
            backgroundColor: 'transparent',
            background: 'linear-gradient(to top, #000C57, transparent)'
          }}
        />
      </Box>
    </>
  );
}

export default SidebarTopSection;
