import { InputBaseComponentProps, SliderProps } from '@mui/material';
import { DataApiInputParams } from 'src/types/api';
import { LOOKUP } from 'src/types/enum';
import { IResponseFeedback } from './types';
import { BaseItem } from 'src/types/base';
export const MESSAGE_PAGE_SIZE = 100;
export const CHAT_LIST_PAGE_SIZE = 30;

export const Fields = {
  Temperature: 'Temperature',
  Tokens: 'Max length (tokens)',
  Sequences: 'Stop sequences',
  Probabilities: 'Top probabilities',
  Frequency: 'Frequency penalty',
  Presence: 'Presence penalty'
};

export const InputConfig: InputBaseComponentProps = {
  inputMode: 'numeric'
  // pattern: '[0-9]*'
};

export const SliderConfig: SliderProps = {
  marks: true,
  valueLabelDisplay: 'on'
};

export const isDifferentDay = (prevDate: string, currDate: string) => {
  return (
    currDate.toString().split('T')[0] !== prevDate?.toString().split('T')[0]
  );
};

export const getMessageTemplate = (
  textInput: string,
  chatId: number,
  model: BaseItem
) => [
  {
    chat_id: chatId,
    id: null,
    message: textInput,
    parent_id: null,
    ts: new Date().toISOString(),
    type: 'q',
    model_id: model?.id,
    model_name: model?.name,
    model_display_name: model?.display_name
  }
];

export const getParamtersFomattedData = (
  data: any[]
): { [key: string]: string | number } => {
  let updatedData = {};
  data.forEach((item) => {
    updatedData[item.name] =
      item.data_type === 'text' ? item.value : parseFloat(item.value);
  });
  return updatedData;
};

const getFormattedPatchParameters = (
  data: { [z: string]: number | string }[],
  updated: { [key: string]: number | string }
): string => {
  let updatedData: { id: number | string; v: string }[] = data.map((item) => {
    return { id: item.id, v: `${updated[item.name]}` };
  });
  return JSON.stringify(updatedData);
};

export const chatListApiParams = (pageNumber: number) => {
  return { params: { p: pageNumber, ps: CHAT_LIST_PAGE_SIZE } };
};

export const getChatMessagesApiParams = (
  chatId: number,
  pageNumber: number
) => {
  return {
    params: { p: pageNumber, ps: MESSAGE_PAGE_SIZE, chat_id: chatId }
  };
};

export const getChatParametersApiParams = (chatId: number) => {
  return {
    params: { chat_id: chatId }
  };
};

export const getUpdateParametersApiParams = (
  data,
  updatedData
): DataApiInputParams => {
  return {
    params: { params: getFormattedPatchParameters(data, updatedData) }
  };
};

export const getReactionApiParams = (
  id,
  value,
  details?: IResponseFeedback
): DataApiInputParams => {
  let rDetails: any = {};
  if (details?.feedbackId?.length) {
    rDetails.feedback_detail_ids = details.feedbackId.join(',');
  }
  if (details?.feedbackComment) {
    rDetails.feedback_comment = details.feedbackComment;
  }

  return {
    params: {
      id: id,
      feedback_id: value,
      ...rDetails
    }
  };
};

export const getReactionsKeyValue = (lookups) => {
  let reactions: { [code: string]: number } = {};
  lookups[LOOKUP.REACTION]?.forEach((item) => {
    reactions[item.code] = item.id;
  });
  return reactions;
};

export const getHistoryStatus = (chats, chatId) => {
  if (chatId && chats.length) {
    return chats.find((item) => item.id === chatId)?.history_context;
  }
  return true;
};

export const addButtonsAfterDocument = (text: string) => {
  if (text) {
    text = text.replace(/\[Document: ([^\]]+)\]/gi, (match, docNumbers) => {
      const docsArray = docNumbers.split(',').map((num: string) => num.trim());
      const newDocs = docsArray.map(
        (num: string) => `[Document: ${num}](#button)`
      );
      return newDocs.join(' ');
    });
    return text;
  }
};

export const sortFiles = (formattedMessage) => {
  const pattern = /document: (\d+)/gi;
  const matches = formattedMessage?.response?.match(pattern);
  if (!matches) return formattedMessage?.files;
  const orderMap = new Map();
  let order = 0;
  for (const match of matches) {
    const normalizedMatch = match.toLowerCase();
    if (!orderMap.has(normalizedMatch)) {
      orderMap.set(normalizedMatch, order++);
    }
  }
  return formattedMessage?.files.sort((a, b) => {
    const keyA = `document: ${a.document_id}`;
    const keyB = `document: ${b.document_id}`;
    return orderMap.get(keyA) - orderMap.get(keyB);
  });
};
