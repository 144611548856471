import { Popper, autocompleteClasses, styled } from '@mui/material';

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    maxHeight: 180,
    width: '100%',
    overflowY: 'auto'
  }
});
